import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Accordion, Card, Button } from 'react-bootstrap';
import { GrooveIconSvg, GrooveTextBox } from 'tfo-groove';
import Dropzone from 'react-dropzone';
import { ProgressBar } from 'react-bootstrap';
//actions
import {
  setClientStoryUpload,
  isUploadCompleted,
  removeClientStoryUpload,
  removeRecentClientStoryUpload,
  updateHTMLStringForPPT,
} from '../../../Actions/ConfigureProgram.Action';
//components
import fileDownload from '../../../Static/images/360cvm/fileDownload.svg';
import fileDownloadWhite from '../../../Static/images/360cvm/fileDownloadWhite.svg';
import { HttpGetDownloadFile, HttpGet, GetSubMenuId } from '../../../Utilities/HTTPUtil';
import upload from './../../../Static/images/svg/uploadfill.svg';
import {  findInvalidChars, removeInvalidChars,HasProperty, IsValidText } from '../../../Utilities/Validations';
import ClientFileItem from './ClientFileItem';
import CVMWarningModal from '../../Shared/CVMWarningModal/CVMWarningModal';
import CVMSaveModal from '../../Shared/CVMSaveModal/CVMSaveModal';
import CVMModal from '../../Shared/CVMModal/CVMModal';
import fileExcel from '../../../Static/images/360cvm/fileExcel.svg';
import { FormDateDDMMMYYY, FormTimeAPM, trimFieldForFileName } from '../../../Utilities/Formatter';

import ReactTooltip from 'react-tooltip';
import keyBusinessIcon from '../../../Static/images/svg/Key Business.svg';
import businessOutcomeIcon from '../../../Static/images/svg/Business outcome.svg';
import innovationIcon from '../../../Static/images/svg/Innovation.svg';
import { IsValid } from '../../../Utilities/Validations';
import {
  setClientStory,
  handleUpdateClientStory
} from '../../../Actions/ConfigureProgram.Action';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromHTML, convertFromRaw, ContentState, convertToRaw, createFromBlockArray  } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import pptxgen from "pptxgenjs";

const ClientStoryPanel = (function () {
  let progress;
  class ClientStoryPanelPresentational extends Component {
    render() {
      let status = this.props.mainSelectedProject.projectDetails.status;
      const keyBusiChallengesInfo = "Provide detailed information of the challenges and opportunities being identified for the project.";
      const innovEnablersInfo = "Include information of all activities conducted to make the project successful.";
      const busiOutcomeInfo = "Include the business value impact generated with the help of the project.";
      const { clientStory }  = this.props;
      const { keyBusinessChallenges, innovationEnablers, businessOutcomes } = clientStory;
      const {
        show_keyBusinessChallenges,
        show_innovationEnablers,
        show_businessOutcomes
      } = this.props.editorPlaceholderAction
      const shouldUCSDisabled = this.props.shouldDisableUCSButton();
      const invalidEditor = this.props.inValidEditorFields.keyBusinessChallenges || this.props.inValidEditorFields.innovationEnablers || this.props.inValidEditorFields.businessOutcomes;
      const shouldDisableDownloadCSPPTButton = this.props.shouldDisableDownloadCSPPTButton(); 
      // let userApprover =
      //   (!this.props.isPPC && !this.props.isBA && !this.props.isSuperAdmin) ||
      //   (this.props.isSuperAdmin && status === 'Awaiting Approval');
      return (
        <React.Fragment>
          <Accordion className="configure-client-collapse with-additional-header-buttons">
            <Card
              id="configCardID"
              className={
                this.props.hasNoAttachment || this.props.hasOldClientStoryFileError ? 'configure-error' : ''
              }
            >
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <div className='clientStory-container'>
                    <div>
                      <div><img className="clienStory-label-icon" src={keyBusinessIcon} alt="key-business-challenges" /> Key Business Challenges <span className='asterisk-red'>*</span>
                        <a data-tip data-for="keyBusiChallengesInfo">
                          <div className="info-icon">
                            <GrooveIconSvg
                              size="large"
                              name="info-circle"
                              iconStyle="solid"
                              iconColor="stat-alternate"
                            />
                          </div>
                        </a>
                        <ReactTooltip id="keyBusiChallengesInfo" type="dark" effect="solid">
                          <span>
                            {keyBusiChallengesInfo}
                          </span>
                        </ReactTooltip>
                      </div>
                      <div>
                      <Editor
                        id="keyBusinessChallenges"
                        readOnly={!this.props.isSuperAdmin&&this.props.basicDetails.overallStatus=='Approved'&&true}
                        onFocus={(e)=>this.props.onEditorFocus(e, "keyBusinessChallenges")}
                        onBlur={(e)=>this.props.onEditorBlur(e,"keyBusinessChallenges")}
                        placeholder={show_keyBusinessChallenges ? keyBusiChallengesInfo : ""}
                        editorState={this.props.editorStates["keyBusinessChallenges"]}
                        wrapperClassName={`client-story-editor-wrapper-class 
                        ${((this.props.hasNoAttachment || this.props.hasOldClientStoryFileError) 
                          && !keyBusinessChallenges.length) && 'client-story-editor-error-wrapper-class'} ${this.props.inValidEditorFields.keyBusinessChallenges ? 'client-story-editor-error-wrapper-class' : ''}`}
                        editorClassName="client-story-editor-class"
                        toolbarClassName="client-story-toolbar-class"
                        toolbar={{
                          options: ["inline","list"],
                          inline: { options: ['bold', 'italic', 'underline']  },
                          list: {  options: ['unordered', 'ordered'] },
                        }}
                        handlePastedText={() => false}
                        onEditorStateChange={(e)=> this.props.onEditorStateChange(e, "keyBusinessChallenges")}
                        handleBeforeInput={(e) => this.props.handleBeforeInput(e, 'keyBusinessChallenges')}
                      />
                      </div>
                    </div>
                    <div>
                      <div><img className="clienStory-label-icon" src={innovationIcon} alt="innovation-enablers" /> Innovation Enablers <span className='asterisk-red'>*</span>
                        <a data-tip data-for="innovEnablersInfo">
                          <div className="info-icon">
                            <GrooveIconSvg
                              size="large"
                              name="info-circle"
                              iconStyle="solid"
                              iconColor="stat-alternate"
                            />
                          </div>
                        </a>
                        <ReactTooltip id="innovEnablersInfo" type="dark" effect="solid">
                          <span>
                            {innovEnablersInfo}
                          </span>
                        </ReactTooltip>
                      </div>
                      <div>
                      <Editor
                        id="innovationEnablers"
                        readOnly={!this.props.isSuperAdmin&&this.props.basicDetails.overallStatus=='Approved'&&true}
                        onFocus={(e)=>this.props.onEditorFocus(e, "innovationEnablers")}
                        onBlur={(e)=>this.props.onEditorBlur(e,"innovationEnablers")}
                        placeholder={show_innovationEnablers ? innovEnablersInfo : ""}
                        editorState={this.props.editorStates["innovationEnablers"]}
                        wrapperClassName={`client-story-editor-wrapper-class 
                        ${((this.props.hasNoAttachment || this.props.hasOldClientStoryFileError) && !innovationEnablers.length) && 'client-story-editor-error-wrapper-class'} ${this.props.inValidEditorFields.innovationEnablers ? 'client-story-editor-error-wrapper-class' : ''}`}
                        editorClassName="client-story-editor-class"
                        toolbarClassName="client-story-toolbar-class"
                        toolbar={{
                          options: ["inline","list"],
                          inline: { options: ['bold', 'italic', 'underline']  },
                          list: {  options: ['unordered', 'ordered'] },
                        }}
                        onEditorStateChange={(e)=> this.props.onEditorStateChange(e, "innovationEnablers")}
                        handleBeforeInput={(e) => this.props.handleBeforeInput(e, 'innovationEnablers')}
                        handlePastedText={() => false}
                      />
                      </div>
                    </div>
                    <div>
                      <div><img className="clienStory-label-icon" src={businessOutcomeIcon} alt="business-outcome" />Business Outcome <span className='asterisk-red'>*</span>
                        <a data-tip data-for="busiOutcomeInfo">
                          <div className="info-icon">
                            <GrooveIconSvg
                              size="large"
                              name="info-circle"
                              iconStyle="solid"
                              iconColor="stat-alternate"
                            />
                          </div>
                        </a>
                        <ReactTooltip id="busiOutcomeInfo" type="dark" effect="solid">
                          <span>
                            {busiOutcomeInfo}
                          </span>
                        </ReactTooltip>
                      </div>
                      <div>
                      <Editor
                        id="businessOutcomes"
                        readOnly={!this.props.isSuperAdmin&&this.props.basicDetails.overallStatus=='Approved'&&true}
                        onFocus={(e)=>this.props.onEditorFocus(e, "businessOutcomes")}
                        onBlur={(e)=>this.props.onEditorBlur(e,"businessOutcomes")}
                        placeholder={show_businessOutcomes ? busiOutcomeInfo : ""}
                        editorState={this.props.editorStates["businessOutcomes"]}
                        wrapperClassName={`client-story-editor-wrapper-class 
                        ${((this.props.hasNoAttachment || this.props.hasOldClientStoryFileError) && !businessOutcomes.length) && 'client-story-editor-error-wrapper-class'} ${this.props.inValidEditorFields.businessOutcomes ? 'client-story-editor-error-wrapper-class' : ''}`}
                        editorClassName="client-story-editor-class"
                        toolbarClassName="client-story-toolbar-class"
                        toolbar={{
                          options: ["inline","list"],
                          inline: { options: ['bold', 'italic', 'underline']  },
                          list: {  options: ['unordered', 'ordered'] }
                        }}
                        onEditorStateChange={(e)=> this.props.onEditorStateChange(e, "businessOutcomes")}
                        handleBeforeInput={(e) => this.props.handleBeforeInput(e, 'businessOutcomes')}
                        handlePastedText={() => false}
                      />
                      </div>
                    </div>
                  </div>
                  <div
                    className={`client-story-btn-parent`}
                  >
                    <div  onClick={ !shouldUCSDisabled && (!invalidEditor ? this.props.handleUpdateClientStory : this.props.handleShowInvalidEditor)} 
                    className={`${!shouldUCSDisabled ? 'download-client-story-btn-container ' : 'download-client-story-disabled-btn-container'}`}>
                      <span
                        className={`download-template-header-btn`}
                      >
                        Update Client Story
                      </span>
                    </div>
                    <div  onClick={!shouldDisableDownloadCSPPTButton && this.props.onClickDownloadedFile}
                     className={`${!shouldDisableDownloadCSPPTButton ?'download-client-story-btn-container' : 'download-client-story-disabled-btn-container'}`}>
                      <img className="fileDownload" src={fileDownload} alt="file-Download" />
                      <img className="fileDownloadWhite" src={fileDownloadWhite} alt="file-Download" />
                      <span
                        className={`download-template-header-btn`}
                      >
                        Download Client Story
                      </span>
                    </div>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <div className="client-upload-collapse-container">
                  <div className="bodytext18-medium-midnight">Client Story</div>
                </div>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  <GrooveIconSvg
                    id="basicDetailsExpand"
                    customClassName="accordion-button"
                    size="large"
                    name="chevron-down"
                    iconStyle="solid"
                    iconColor="stat-neutral"
                  />
                </Accordion.Toggle>
              </Card.Header>
            </Card>
          </Accordion>
          {(this.props.hasNoAttachment || this.props.hasOldClientStoryFileError) ? (
            <div className="client-invalid-reason">{this.props.errorUpload}</div>
          ) : null}
          {/* MODAL */}
          <CVMModal
            title={''}
            show={this.props.showRemoveModal}
            onCloseModal={this.props.onDeleteUpload}
            customclass={'select-project-modal'}
            content={
              <CVMWarningModal
                handleCancel={this.props.onDeleteUpload}
                handleYes={this.props.removeUploadedFile}
                body={
                  <div>
                    <h5>Are you sure you want to remove the attachment?</h5>
                  </div>
                }
              />
            }
          />
          <CVMModal
            title={''}
            customclass={'notifyModal-dialog'}
            show={this.props.showInvalidCharModal}
            onCloseModal={this.props.closeInvalidCharModal}
            content={
              <CVMSaveModal
                body={'Given charecters are not supported and has been removed: ' + this.props.invalidChars.join(', ')}
                bodySecondary={'Click the Cancel button to review the client story, or the Save button to apply the changes.'}
                handleCancel={this.props.closeInvalidCharModal}
                handleYes={() => {
                  this.props.handleUpdateClientStory();
                  this.props.closeInvalidCharModal();
                }}
              />
            }
          />
        </React.Fragment>
      );
    }
  }

  class ClientStoryPanelContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        fileIsValid: true,
        progressNow: 0,
        disableUpload: false,
        errorUpload: '',
        showRemoveModal: false,
        showConfirmRemoveModal: false,
        editorStates:{
          keyBusinessChallenges: '',
          innovationEnablers: '',
          businessOutcomes: '',
        },
        editorPlaceholderAction: {
          show_keyBusinessChallenges: true,
          show_innovationEnablers: true,
          show_businessOutcomes: true,
        },
        showInvalidCharModal: false,
        invalidChars: [],
      };
    }

    componentDidMount(){
      if(this.props.ConfigureProgramData && this.props.ConfigureProgramData.clientStory){
        const obj = {...this.state.editorStates}
        const placeholderActionObj = {...this.state.editorPlaceholderAction};
          for (const key in this.props.ConfigureProgramData.clientStory) {
            if (this.props.ConfigureProgramData.clientStory[key] && this.props.ConfigureProgramData.clientStory[key].length) {
              const blocksFromHTML = convertFromHTML(this.props.ConfigureProgramData.clientStory[key]);
              const content = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
              );
              obj[key] = EditorState.createWithContent(content);
              if(placeholderActionObj.hasOwnProperty(key)){
                placeholderActionObj[`show_${key}`] = false;
              }
            }
          } 
          this.setState
          ({
            editorStates: obj,
            editorPlaceholderAction: placeholderActionObj
          })
      } else {
        //if clientStory null handle editorstate
      }
    }

    componentDidUpdate(prevProps) {
      try {
        if (prevProps.hasNoCSAttachment !== this.props.hasNoCSAttachment && this.props.hasNoCSAttachment) {
          let errorMessage = 'Missing required field.';
          this.setState({ errorUpload: errorMessage });
        }
        if(prevProps.hasOldClientStoryFileError !== this.props.hasOldClientStoryFileError && this.props.hasOldClientStoryFileError){
          let errorMessage = 'Update missing information. ';
          this.setState({ errorUpload: errorMessage });
        }
      } catch (e) {
        console.log(e);
      }

    }

    onClickDownloadFile = async () => {
      
      let subMenuId = GetSubMenuId("Project Data Entry")
      let supportDocs = await HttpGet('ProfileMenu/GetSupportDocuments?SubMenuID=' + subMenuId);
      if (supportDocs.status !== 204) {
        let document = {};
        let idx = supportDocs.findIndex((doc) => doc.DocumentType === '3');
        if (idx !== -1) {
          document = supportDocs[idx];
          HttpGetDownloadFile(
            `ProfileMenu/DownloadSupportDocuments?ID=${document.ID}`,
            document.FileName
          );
        }
      }
    };

    onDropAccepted = async (acceptedFiles) => {
      try {
        let configureProgramData = this.props.ConfigureProgramData;
        let recentlyUploadedClientStory =
          configureProgramData.mainSelectedProject.projectDetails.recentlyUploadedClientStory;
        let hasRecentlyUploaded = HasProperty(recentlyUploadedClientStory, 'FileName');
        let recentlyRemoveUploadedId = configureProgramData.removeClientStoryFileId;
        let uploadFiles = acceptedFiles;
        let uploadCount = uploadFiles.length;
        let fileCount = this.props.ConfigureProgramData.clientStoryFile.file.length;
        let uploadedFileCount = hasRecentlyUploaded ? 1 - (recentlyRemoveUploadedId !== 0 ? 1 : 0) : 0;
        if (fileCount + uploadCount + uploadedFileCount <= 1) {
          if (this.validateFiles(uploadFiles)) {
            uploadFiles = this.props.ConfigureProgramData.clientStoryFile.file.concat(uploadFiles);
            await this.props.setClientStoryUpload(uploadFiles, new Date());
            if (
              document
                .getElementsByClassName('with-additional-header-buttons')[0]
                .children[0].children[0].className.indexOf('show') === -1
            ) {
              document
                .getElementsByClassName('with-additional-header-buttons')[0]
                .children[0].children[1].children[1].click();
            }
            this.setState(
              {
                errorUpload: '',
                fileIsValid: true,
              },
              () => {
                this.buffProgressBar();
                this.props.onCloseCSAttachmentError();
              }
            );
          }
        } else {
          this.setState({
            errorUpload: 'You are only allowed to upload a maximum of 1 file',
            fileIsValid: false,
          });
        }
      } catch (e) {
        console.log(e);
      }
    };

    onDropRejected = (file) => {
      try {
        let errorCode = file[0].errors[0].code;
        let message = '';

        switch (errorCode) {
          case 'file-invalid-type':
            message =
              'Invalid file format. Please upload file with any of the these formats: (.ppt, .pptx).';
            break;
          case 'too-many-files':
            message = 'You are only allowed to upload a maximum of 1 file.';
            break;
          case 'file-too-large':
            message =
              'File size exceeds permissible limit. Please note maximum file size supported is 10MB.';
            break;
          default:
            break;
        }
        this.props.onCloseCSAttachmentError();
        this.setState({
          fileIsValid: false,
          errorUpload: message,
        });
      } catch (e) {
        console.log(e);
      }
    };

    buffProgressBar = () => {
      try {
        progress = setInterval(() => {
          this.setState({ progressNow: this.state.progressNow + 1, disableUpload: true });
          if (this.state.progressNow === 100) {
            clearInterval(progress);
            this.props.isUploadCompleted(true);
            this.setState({ disableUpload: false });
          }
        }, 10);
      } catch (e) {
        console.log(e);
      }
    };

    validateFiles = (file) => {
      let isValid = true;
      const nfile = file[0];
      const fileExt = nfile.name.split('.');
      const extList = ['pptx', 'ppt'];
      let message = '';
      try {
        if (nfile.size > 10000000) {
          isValid = false;
          message =
            'File size exceeds permissible limit. Please note maximum file size supported is 10MB.';
        } else if (fileExt.length > 2) {
          //for double extension
          isValid = false;
          message = 'Invalid File Name. File Name should not contain a dot (.)';
        } else if (
          nfile.type !== 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        ) {
          isValid = false;
          message =
            'Invalid file format. Please upload file with any of the these formats: (.ppt, .pptx).';
        } else if (!extList.includes(fileExt[fileExt.length - 1])) {
          isValid = false;
          message =
            'Invalid file format. Please upload file with any of the these formats: (.ppt, .pptx).';
        } else if (fileExt[0].length > 100) {
          isValid = false;
          message =
            'File name exceeds 100 maximum character limit. Please update file name then upload again.';
        }

        this.props.onCloseCSAttachmentError();
        this.setState({
          fileIsValid: isValid,
          errorUpload: message,
        });
      } catch (e) {
        console.log(e);
        isValid = false;
      }

      return isValid;
    };

    onDeleteUpload = () => {
      try {
        this.setState(
          {
            showRemoveModal: !this.state.showRemoveModal,
          },
          () => {
            if (this.state.showRemoveModal) {
              clearInterval(progress);
            } else {
              if (this.state.progressNow !== 100) {
                this.buffProgressBar();
              }
            }
          }
        );
      } catch (e) {
        console.log(e);
      }
    };

    removeUploadedFile = () => {
      try {
        this.setState(
          {
            progressNow: 0,
            showRemoveModal: false,
            disableUpload: false,
          },
          () => {
            this.props.removeClientStoryUpload();
            this.props.isUploadCompleted(false);
          }
        );
      } catch (e) {
        console.log(e);
      }
    };

    onClickConfirmRemoveModal = async (e) => {
      this.setState({ showConfirmRemoveModal: e });
    };

    onDeleteUploaded = async () => {
      let recentlyUploadedClientStory =
        this.props.ConfigureProgramData.mainSelectedProject.projectDetails
          .recentlyUploadedClientStory;
      let hasRecentlyUploaded = HasProperty(recentlyUploadedClientStory, 'FileName');
      this.props.isUploadCompleted(false);
      this.props.removeClientStoryUpload();
      if (hasRecentlyUploaded) {
        this.props.removeRecentClientStoryUpload(recentlyUploadedClientStory);
      }
      this.setState({
        showConfirmRemoveModal: false,
        progressNow: 0,
        disableUpload: false,
        errorUpload: null,
        fileIsValid: true,
      });
    };

    onClickDownloadedFile = async() =>{
      const clientName = this.props.SharedData.selectedClient.label;
      const subMenuId = GetSubMenuId("Project Data Entry")
      const {projectID, projectDeliveryCenterID, projectName, programName} = this.props.ConfigureProgramData.basicDetails
      await HttpGetDownloadFile(
                `ProjectDataEntry/DownloadClientStory?projectId=${projectID}&ProjectDeliveryCenterID=${projectDeliveryCenterID}&SubMenuID=${subMenuId}`,
                `${clientName}_${programName}_${projectName}_${projectID}.pptx`
              );
    }

    downloadFile = async (fileItem) => {
      let fileType = fileItem.FileName.substring(fileItem.FileName.lastIndexOf('.') + 1);
      let fileName = fileItem.FileName.substring(0, fileItem.FileName.lastIndexOf('.'));

      let projectName = trimFieldForFileName(
        this.props.ConfigureProgramData.mainSelectedProject.projectDetails.clientProjectName,
        100
      );
      let clientName = this.props.SharedData.selectedClient.label;
      let uniqueID = this.props.ConfigureProgramData.mainSelectedProject.projectDetails.projectID;

      let date = `${FormDateDDMMMYYY(fileItem.FileUploadDate, true)}_${FormTimeAPM(
        fileItem.FileUploadDate,
        true
      )}`;
      date = date.replace(':', ' ');
      date = date.replace(/ /g, '_');
      date = date + '';

      let fileNameLength = `${clientName}_${projectName}_${uniqueID}__${date}.${fileType}`.length;
      let lastIndex = 200 - fileNameLength;
      fileName = trimFieldForFileName(fileName, lastIndex);

      let downloadFileName = `${clientName}_${projectName}_${uniqueID}_${fileName}_${date}.${fileType}`;
      let subMenuId = GetSubMenuId("Project Data Entry")

      await HttpGetDownloadFile(
        `ProjectDataEntry/DownloadClientStoryFile?ClientStoryFileID=${fileItem.Id}&SubMenuID=${subMenuId}&clientProjectID=${uniqueID}`,
        downloadFileName
      );
    };

    onEditorStateChange = (editorState, type) =>{
      const { clientStory } = this.props.ConfigureProgramData;
      const currentValue = editorState.getCurrentContent();
      const editorValue = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      const plainValue = editorState.getCurrentContent().getPlainText();
      const value = plainValue && plainValue.trim().length ? editorValue : plainValue.trim();

      if (!this.props.editedAnyFieldOnce && this.props.ConfigureProgramData.basicDetails.overallStatus === 'Approved') {
        this.props.checkForInvalidEmailsOnLanding();
        this.props.setEditedAnyFieldOnce(true);
      }

      if (plainValue.trim().length <= 2000) {
        this.setState({
          editorStates: {
            ...this.state.editorStates,
            [type]: editorState
          }
        })
        const updatedCSObj = { ...clientStory, [type]: updateHTMLStringForPPT(value)}
        const {
          keyBusinessChallenges,
          innovationEnablers,
          businessOutcomes
        } = updatedCSObj;
        this.props.setClientStory(updatedCSObj);
        
        if (plainValue.length && !IsValidText(plainValue)) {
          this.props.changeInvalidEditorFields(type, true);
        } else {
          this.props.changeInvalidEditorFields(type, false);
        }

        if((this.props.hasNoCSAttachment || this.props.hasOldClientStoryFileError ) 
          && keyBusinessChallenges.length && innovationEnablers.length && businessOutcomes.length){
          this.props.onCloseCSAttachmentError();
        }
      } else if(plainValue.length > 2000){
        const fText = String(plainValue).slice(0, 2000);
        this.setState({
          editorStates: {
            ...this.state.editorStates,
            [type]: EditorState.createWithContent(ContentState.createFromText(fText))
          }
        })
        if (plainValue.length && !IsValidText(plainValue)) {
          this.props.changeInvalidEditorFields(type, true);
        } else {
          this.props.changeInvalidEditorFields(type, false);
        }
      }

    }

    handleBeforeInput = (input, type) => {
      const textLength = this.state.editorStates[type].getCurrentContent().getPlainText().length;
      if (input && textLength >= 2000) {
          return 'handled';
      }
      return 'not-handled';
    }
    isAnyCurrentCSFieldEmpty = () => {
      const { clientStory } = this.props.ConfigureProgramData;
      const {
        keyBusinessChallenges,
        innovationEnablers,
        businessOutcomes
      } = clientStory;

        return !keyBusinessChallenges.length || !innovationEnablers.length || !businessOutcomes.length
    }
    areCurrentCSFieldsSame = () => {
      const { clientStory } = this.props.ConfigureProgramData;
      const {
        exKeyBusinessChallenges,
        exInnovationEnablers,
        exBusinessOutcomes,
        keyBusinessChallenges,
        innovationEnablers,
        businessOutcomes
      } = clientStory;

      return (keyBusinessChallenges.length && exKeyBusinessChallenges === keyBusinessChallenges) &&
      (innovationEnablers.length && exInnovationEnablers === innovationEnablers) &&
      (businessOutcomes.length && exBusinessOutcomes === businessOutcomes)
    }
    areCurrentCSFieldsSpecialChar = () => {
      const editorState = this.state.editorStates;

      const {keyBusinessChallenges, innovationEnablers, businessOutcomes} = editorState;
      
      if(keyBusinessChallenges && innovationEnablers && businessOutcomes){
        return (
          !removeInvalidChars(keyBusinessChallenges.getCurrentContent().getPlainText()).trim().length ||
          !removeInvalidChars(innovationEnablers.getCurrentContent().getPlainText()).trim().length ||
          !removeInvalidChars(businessOutcomes.getCurrentContent().getPlainText()).trim().length
        );
      } else {
        return false;
      }
    }
    shouldDisableDownloadCSPPTButton = () => {
      return this.isAnyCurrentCSFieldEmpty() || this.areCurrentCSFieldsSpecialChar() || !this.areCurrentCSFieldsSame();
    }
    shouldDisableUCSButton = () => {
      return this.isAnyCurrentCSFieldEmpty()|| this.areCurrentCSFieldsSpecialChar() || this.areCurrentCSFieldsSame();
    }

    onEditorFocus = (e, type) =>{
      if(this.state.editorStates.hasOwnProperty(type)){
        this.setState({
          editorPlaceholderAction:{
            ...this.state.editorPlaceholderAction,
            [`show_${type}`]:false
          }
      })
      }
    }

    onEditorBlur = (e, type) =>{
      const editorState = this.state.editorStates;
      if(editorState.hasOwnProperty(type)){
        this.setState({
          editorPlaceholderAction:{
            ...this.state.editorPlaceholderAction,
            [`show_${type}`]:editorState[type] &&  editorState[type].length ? false : true
          }
        })
      }
    }

    handleShowInvalidEditor = () => {
      const removeInvalidCharsInRaw = (raw) => {
        return {
          ...raw,
          blocks: raw.blocks.map((block) => {
            return {
              ...block,
              text: removeInvalidChars(block.text),
            };
          }),
        };
      };
      
      const data = this.state.editorStates;
      const kbcPlain = data["keyBusinessChallenges"].getCurrentContent().getPlainText();
      const iePlain = data["innovationEnablers"].getCurrentContent().getPlainText();
      const boPlain = data["businessOutcomes"].getCurrentContent().getPlainText();

      const kbcEditorDraft = removeInvalidCharsInRaw(
        convertToRaw(data['keyBusinessChallenges'].getCurrentContent())
      );
      
      const ieEditorDraft = removeInvalidCharsInRaw(
        convertToRaw(data['innovationEnablers'].getCurrentContent())
      );
      
      const boEditorDraft = removeInvalidCharsInRaw(
        convertToRaw(data['businessOutcomes'].getCurrentContent())
      );
      
      const kbcEditorValue = draftToHtml(kbcEditorDraft);
      const ieEditorValue = draftToHtml(ieEditorDraft);
      const boEditorValue = draftToHtml(boEditorDraft);

      const kbcValue = kbcPlain && kbcPlain.trim().length ? kbcEditorValue : kbcPlain.trim();
      const ieValue = iePlain && iePlain.trim().length ? ieEditorValue : iePlain.trim();
      const boValue = boPlain && boPlain.trim().length ? boEditorValue : boPlain.trim();

      const { clientStory } = this.props.ConfigureProgramData;

      const updatedCSObj = { 
        ...clientStory, 
        ["keyBusinessChallenges"]: updateHTMLStringForPPT(kbcValue),
        ["innovationEnablers"]: updateHTMLStringForPPT(ieValue),
        ["businessOutcomes"]: updateHTMLStringForPPT(boValue)
      }

      this.setState({
        editorStates: {
          ...this.state.editorStates,
          ['keyBusinessChallenges']: EditorState.createWithContent(
            ContentState.createFromBlockArray(
              htmlToDraft(kbcValue).contentBlocks,
              htmlToDraft(kbcValue).entityMap
            )
          ),
          ['innovationEnablers']: EditorState.createWithContent(
            ContentState.createFromBlockArray(
              htmlToDraft(ieValue).contentBlocks,
              htmlToDraft(ieValue).entityMap
            )
          ),
          ['businessOutcomes']: EditorState.createWithContent(
            ContentState.createFromBlockArray(
              htmlToDraft(boValue).contentBlocks,
              htmlToDraft(boValue).entityMap
            )
          ),
        },
      });

      this.props.setClientStory(updatedCSObj);

      const invalidChar = [...findInvalidChars(kbcPlain), ...findInvalidChars(iePlain), ...findInvalidChars(boPlain)];
      this.setState({
        showInvalidCharModal: true,
        invalidChars: [...new Set(invalidChar)]
      })

      this.props.changeInvalidEditorFields("keyBusinessChallenges", false);
      this.props.changeInvalidEditorFields("innovationEnablers", false);
      this.props.changeInvalidEditorFields("businessOutcomes", false);
    }

    closeInvalidCharModal = () => {
      this.setState({
        showInvalidCharModal: false
      })
    }

    render() {
      const { clientStoryFile, mainSelectedProject, removeClientStoryFileId, clientStory, basicDetails } =
        this.props.ConfigureProgramData;
      const { userProfiles, isSuperAdmin, currentUserRoleID } = this.props.UserInformationData;
      const { selectedClient } = this.props.SharedData;
      const { RVDComponents } = this.props.AccessValidationData;
      let clientID = selectedClient.value;
      let isExistingPPC = userProfiles.some(
        (x) => [12].some((prof) => prof === x.ProfileID) && x.ClientMasterId === clientID
      );
      let isExistingBA = userProfiles.some(
        (x) => [15].some((prof) => prof === x.ProfileID) && x.ClientMasterId === clientID
      );
      return (
        <ClientStoryPanelPresentational
          //States
          errorUpload={this.state.errorUpload}
          fileIsValid={this.state.fileIsValid}
          progressNow={this.state.progressNow}
          disableUpload={this.state.disableUpload}
          showRemoveModal={this.state.showRemoveModal}
          showConfirmRemoveModal={this.state.showConfirmRemoveModal}
          editorStates={this.state.editorStates}
          editorPlaceholderAction={this.state.editorPlaceholderAction}
          //funtions
          onClickDownloadFile={this.onClickDownloadFile}
          onDropAccepted={this.onDropAccepted}
          onDropRejected={this.onDropRejected}
          removeUploadedFile={this.removeUploadedFile}
          onDeleteUpload={this.onDeleteUpload}
          onClickConfirmRemoveModal={this.onClickConfirmRemoveModal}
          onDeleteUploaded={this.onDeleteUploaded}
          onClickDownloadedFile={this.onClickDownloadedFile}
          downloadFile={this.downloadFile}
          onEditorStateChange={this.onEditorStateChange}
          inValidEditorFields={this.props.inValidEditorFields}
          handleBeforeInput={this.handleBeforeInput}
          shouldDisableUCSButton={this.shouldDisableUCSButton}
          shouldDisableDownloadCSPPTButton={this.shouldDisableDownloadCSPPTButton}
          onEditorFocus={this.onEditorFocus}
          onEditorBlur={this.onEditorBlur}
          //props
          hasNoAttachment={this.props.hasNoCSAttachment}
          hasOldClientStoryFileError={this.props.hasOldClientStoryFileError}
          clientStory={clientStory}
          RVDComponents={RVDComponents}
          //reducers
          setClientStory={this.props.setClientStory}
          handleUpdateClientStory={this.props.handleUpdateClientStory}
          isSuperAdmin={isSuperAdmin}
          basicDetails={basicDetails}
          Files={clientStoryFile}
          mainSelectedProject={mainSelectedProject}
          removeClientStoryFileId={removeClientStoryFileId}
          selectedClient={selectedClient}
          isPPC={currentUserRoleID === 12 || (currentUserRoleID === 16 && isExistingPPC)}
          isBA={currentUserRoleID === 15 || (currentUserRoleID === 16 && isExistingBA)}
          showInvalidCharModal={this.state.showInvalidCharModal}
          handleShowInvalidEditor={this.handleShowInvalidEditor}
          closeInvalidCharModal={this.closeInvalidCharModal}
          invalidChars={this.state.invalidChars}
        />
      );
    }
  }

  return ClientStoryPanelContainer;
})();

export default connect(
  (state) => {
    return {
      ConfigureProgramData: state.ConfigureProgramData,
      SharedData: state.SharedData,
      UserInformationData: state.userInformation,
      AccessValidationData: state.AccessValidationData
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          setClientStoryUpload,
          isUploadCompleted,
          removeClientStoryUpload,
          removeRecentClientStoryUpload,
          setClientStory,
          handleUpdateClientStory
        }
      ),
      dispatch
    )
)(ClientStoryPanel);
