import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PDE, PROJ_DATA_APP_L3, ACC_PROJ } from '../../../Constants/Modules';
import { GrooveIconSvg, GrooveButton, GrooveTextBox, GrooveSwitchToggle, GrooveSelectMenuBasic, GrooveInput } from 'tfo-groove';
import { Accordion, Card, Button, Row } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ReactTooltip from 'react-tooltip';
import { withRouter, Link } from 'react-router-dom';
//components
import { GetSubMenuId, HttpGet, HttpPost } from '../../../Utilities/HTTPUtil';
import { LogUserSiteAction, LogRVDprojectAction } from '../../../Utilities/AccessManagementUtil';
import {
  IsObjEmpty,
  HasValue,
  IsValidStr,
  IsValid,
  HasProperty,
  IsArrayEmpty,
  IsArray,
  IsValidText,
  IsValidAccentureEmail
} from '../../../Utilities/Validations';
import { TryGetNodeAlt, TryGetObjValueAlt, FormDateDDMMMYYY, formatMultiLabel } from '../../../Utilities/Formatter';

// Component Imports
import CVMModal from '../../Shared/CVMModal/CVMModal';
import CustomBOIModal from '../../ProgramProjectConfiguration/CustomBOIModal';
import CVMSingleApprovalRequestModal from '../../Shared/CVMSingleApprovalRequestModal/CVMSingleApprovalRequestModal';
import CVMReportValueRejectionModal from '../../Shared/CVMReportValueRejectionModal/CVMReportValueRejectionModal';
import CVMReportValueViewComments from '../../Shared/CVMReportValueViewComments/CVMReportValueViewComments';
// import CVMReportValueSubmitRejectionModal from '../../Shared/CVMReportValueSubmitRejectionModal/CVMReportValueSubmitRejectionModal';
import CVMToasterNotif from '../../Shared/CVMToasterNotif/CVMToasterNotif';
import CVMWarningModal from '../../Shared/CVMWarningModal/CVMWarningModal';
import CVMAlertModal from '../../Shared/CVMAlertModal/CVMAlertModal';
import CVMSendReminderButton from '../../Shared/CVMReminderButton/CVMSendReminderButton';
import CommentaryModal from '../Modals/CommentaryModal/CommentaryModal';
import CommentaryHistoryModal from '../Modals/CommentaryModal/CommentaryHistoryModal';
import ApproveRejectRadioButton from './ApproveRejectRadioButton';
import AddEditBusinessAdvisor from '../Modals/AddEditBusinessAdvisor/AddEditBusinessAdvisor';
import ClientValueIdentified from './ClientValueIdentified/ClientValueIdentified';
import ClientStoryPanel from './ClientStoryPanel';
import ClientAlignmentPanel from './ClientAlignmentPanel';
import ClientStoryAcknowledgementFileHistory from '../Modals/ClientStoryAcknowledgementFileHistory/ClientStoryAcknowledgementFileHistory';

//actions
import {
  onSaveSelectProject,
  handleParameterChange,
  getSFADetails,
  handleSFACommentChange,
  onClickSelectProjectSFA,
  handleSaveAsDraft,
  handleComputeAll,
  handleResetAll,
  clearClientModalDetails,
  validatePDEForm,
  submitFinalApproval,
  saveRejectionReason,
  handleSubmitRejection,
  setDefaultRadioValue,
  getSRDetails,
  getUpdatedBOI,
  resetRefresh,
  setCumulativeModal,
  validateRejectionComment,
  getClientStoryFileHistory,
  getClientAcknowledgementFileHistory,
  removeClientStoryUpload,
  removeRecentClientStoryUpload,
  removeClientAlignmentUpload,
  removeRecentClientAlignmentUpload,
  isUploadCompleted,
  onSaveBusinessAdvisor,
  onCPPointsYesNo,
  setRVDSelectedTabs,
  getClientFileHistory,
  // updateExpandCollapseBOIView,
  handleUpdateReceiverDetails,
  handleSendReminderPDE
} from '../../../Actions/ConfigureProgram.Action';
import { setConfProgButtonState } from '../../../Actions/DataIngestion.Action';
import { getCustomBOIDetails, isProfileExisting, setEmailError } from '../../../Actions/Shared.Action';
import { getReminderCount } from '../../../Actions/Notifcation.Action';
// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import play from './../../../Static/images/360cvm/play.svg';
import addfiles from './../../../Static/images/360cvm/AddFilesIcon.svg';
import resetAll from './../../../Static/images/svg/ResetAll.svg';
import computeAll from './../../../Static/images/svg/ComputeAll.svg';
import historyIcon from '../../../Static/images/360cvm/History.svg';
import newInfo from '../../../Static/images/svg/new-info.svg';
import ppcIcon from '../../../Static/images/360cvm/DraftingCompassNew.svg';

//css
import './ConfigureProgramStyles.css';
import './ClientStoryAlignment.css';
// import ClientStoryAcknowledgementModal from '../ClientStoryAcknowledgementModal/ClientStoryAcknowledgementModal';
import CPPointsModal from '../Modals/CPPointsModal/CPPointsModal';
//import { DataEvent } from '../../../Utilities/DataEvents';
import { getBusinessAdvisorListByClientID } from '../../../Actions/ProjectConfiguration.Action';

const ConfigureProgram = (function () {
  const IS_RESOURCE_DISABLED = {
    isSelectProjectDisabled: false,
    isCommentDisabled: false,
    isSaveAsDraftDisabled: false,
  };

  class ConfigureProgramPresentational extends Component {


    showRefresh = () => {
      switch(true) {
        case this.props.basicDetails.overallStatus === 'Awaiting Approval' &&  (this.props.isSuperAdmin || this.props.mainSelectedProject.projectDetails.assignedToList.filter((a) => a.label === this.props.emailAddress)):
          return false;
        case ['Approved', 'Awaiting Approval', 'Rejected', 'In Progress', ""].includes(this.props.basicDetails.overallStatus) && !this.props.RVDComponents.rvdParams:
          return true;
        case ['Approved', 'Rejected', 'In Progress', ""].includes(this.props.basicDetails.overallStatus) && this.props.isSuperAdmin:
          return true;
        case [12, 15].includes(this.props.currentUserRoleID):
          return true;
        case this.props.currentUserRoleID === 16 && this.props.isExistingPPCBA:
          return true;
        default:
          return false;
      }
    };

    render() {
      
      let currentUserRoleID = this.props.currentUserRoleID;
      let assignedToList = this.props.mainSelectedProject.projectDetails.assignedToList;
      // let showRefresh =
      //   this.props.basicDetails.overallStatus === 'Awaiting Approval' &&
      //     (this.props.isSuperAdmin || !IsArrayEmpty(assignedToList.filter((a) => a.label === this.props.emailAddress)))
      //     ? false
      //     : ((this.props.basicDetails.overallStatus === 'Awaiting Approval' ||
      //       this.props.basicDetails.overallStatus === 'Approved' ||
      //       this.props.basicDetails.overallStatus === 'Rejected' ||
      //       this.props.basicDetails.overallStatus === 'In Progress') &&
      //       (!this.props.RVDComponents.rvdParams || this.props.isSuperAdmin)) ||
      //     ((this.props.basicDetails.overallStatus === 'Awaiting Approval' ||
      //       this.props.basicDetails.overallStatus === 'Approved') &&
      //       this.props.basicDetails.overallStatus === 'Rejected' ) &&
      //       (currentUserRoleID === 12 ||
      //         currentUserRoleID === 15 ||
      //         (currentUserRoleID === 16 && this.props.isExistingPPCBA));
      //disable rvdparam field when status is for approval and user role is super admin
      const status =
        this.props.mainSelectedProject.programID !== undefined
          ? Object.keys(this.props.mainSelectedProject).length > 0
            ? Object.keys(this.props.mainSelectedProject.projectDetails).length > 0
              ? Object.keys(this.props.mainSelectedProject.projectDetails.status).length > 0
                ? this.props.mainSelectedProject.projectDetails.status
                : ''
              : ''
            : ''
          : '';
      const disableSuperAdmin =
        (status === 'Awaiting Approval' || status === 'Awaiting Approval - Modified') &&
          this.props.isSuperAdmin
          ? true
          : false;
      let modifiedDate =
        this.props.mainSelectedProject.programID !== undefined
          ? Object.keys(this.props.mainSelectedProject).length > 0
            ? Object.keys(this.props.mainSelectedProject.projectDetails).length > 0
              ? IsValid(this.props.mainSelectedProject.projectDetails.pdeModifiedDate)
                ? FormDateDDMMMYYY(new Date(this.props.mainSelectedProject.projectDetails.pdeModifiedDate), true)
                : '-'
              : ''
            : ''
          : '';

      let subOfferings = '';
      if (Object.keys(this.props.basicDetails).length !== 0) {
        this.props.basicDetails.subOfferingList.forEach((subOff) => {
          if (subOfferings === '') {
            subOfferings = subOff.SubOfferingName;
          } else {
            subOfferings = subOfferings + ', ' + subOff.SubOfferingName;
          }
        });
      }

      const opeAccLead = (Object.keys(this.props.basicDetails).length !== 0 && IsValidStr(this.props.basicDetails.oaLead)) ? this.props.basicDetails.oaLead.split(";").join(",") : "";
      let isPPC =
        this.props.loggedInUserRoleID === 12 ||
        (this.props.loggedInUserRoleID === 16 && this.props.isExistingPPC);
      let isSuperAdmin = this.props.isSuperAdmin;
      let isBA =
        this.props.loggedInUserRoleID === 15 ||
        (this.props.loggedInUserRoleID === 16 && this.props.isExistingBA);
      let isIOAdmin =
        (isSuperAdmin &&
          (this.props.basicDetails.assignedToRoleID === 8 ||
            this.props.basicDetails.assignedToRoleID === 16)) ||
        ((currentUserRoleID === 8 || (currentUserRoleID === 16 && this.props.isExistingIO)) &&
          this.props.basicDetails.assignedToRoleID === currentUserRoleID);
      let isExistingFinancial =
        this.props.projectDimensionsList.filter(
          (dim) => dim.dimensionName.toLowerCase() === 'financial'
        ).length > 0;
      // Sort Project Dimension with Financial on the top
      let tempSortprojectDimensionsList = [];
      try {
        tempSortprojectDimensionsList = this.props.projectDimensionsList.sort((dim1, dim2) => {
          return dim1.dimensionID === 1 ? -1 : dim2.dimensionID === 1 ? 1 : 0;
        });
      } catch (e) {
        tempSortprojectDimensionsList = this.props.projectDimensionsList;
      }

      let isAgnosticTargetZero = false;
      let oaLeadList = this.props.mainSelectedProject.projectDetails.oaLeadList || [];
      let cxLeadList = this.props.mainSelectedProject.projectDetails.cxLeadList || [];

      const userEmail = this.props.emailAddress.trim().toLowerCase();
      const ioApproverEmail = HasValue(this.props.basicDetails.ioApprover)
        ? this.props.basicDetails.ioApprover.trim().toLowerCase()
        : '';
      const isFirstSubmission =
        this.props.mainSelectedProject.projectDetails.isFirstSubmission === 1 ? false : true;
      const formatValue = this.props.formatValue;
      let userCX = !IsArrayEmpty((cxLeadList || []).filter((a) => a.label.trim().toLowerCase() === userEmail));

      let CXIOOAL = (
        (!IsArrayEmpty(cxLeadList.filter((cx) => cx.label.trim().toLowerCase() === userEmail)) &&
          IsArrayEmpty(cxLeadList.filter((cx) => cx.label.trim().toLowerCase() === ioApproverEmail)))
        || // for cx -> io -> oal flow
        (
          (
            userEmail === ioApproverEmail ||
            !IsArrayEmpty(cxLeadList.filter((cx) => cx.label.trim().toLowerCase() === userEmail))
          ) &&
          !IsArrayEmpty(cxLeadList.filter((cx) => cx.label.trim().toLowerCase() === ioApproverEmail))
        ) || // for cx + io -> ioa flow
        (
          userEmail === ioApproverEmail &&
          !IsArrayEmpty(oaLeadList.filter((cx) => cx.label.trim().toLowerCase() === userEmail))
        )
        || // for cx -> io + ioa flow
        this.props.isSuperAdmin);

      //PBI 197952 - Update in BA
      const isBANotApp = this.props.businessAdvisorList.length === 1 && this.props.businessAdvisorList[0].value === 0
      let BAdvisors = [];
      if (this.props.businessAdvisorList.length > 0) {
        this.props.businessAdvisorList.forEach((x) => {
          BAdvisors.push(x.label)
        })
      }

      let isCreatorView = isPPC || isBA || (isSuperAdmin && this.props.basicDetails.overallStatus !== 'Awaiting Approval');

      let isOMIDContractIDExisting = !this.props.mainSelectedProject.projectDetails.isOMIDContractIDExisting;
      // Disable SAD SFA button if any of stakeholder is empty or no mapping for CXL, IOL or OAL
      let disableSADSFABtn = !IsValid(this.props.basicDetails.cxLead) || !IsValid(this.props.basicDetails.oaLead) ||
        !IsValid(this.props.basicDetails.projectCreator) || isOMIDContractIDExisting || this.props.anyInvalidEmails;
      //|| !IsValid(this.props.basicDetails.ioApprover)

      let isReminderSenderView = (isPPC || isBA || isSuperAdmin) && this.props.basicDetails.overallStatus === 'Awaiting Approval';

      let isCXLStage = this.props.basicDetails.assignedToRole.trim().toLowerCase() === "cx lead"

      let isOALStage = !IsArrayEmpty
        (oaLeadList.filter((oa) => !IsArrayEmpty(assignedToList.filter(a => oa.label.trim().toLowerCase() === a.label.trim().toLowerCase()))))

      let isReminderCXLOALSenderView = !IsArrayEmpty(cxLeadList.filter((cx) => cx.label.trim().toLowerCase() === userEmail)) && this.props.receiverDetails.isSkippedIOApproval && isOALStage

      let isReminderCXLIOASenderView = !IsArrayEmpty(cxLeadList.filter((cx) => cx.label.trim().toLowerCase() === userEmail)) && ioApproverEmail === this.props.basicDetails.assignedTo.trim().toLowerCase();

      let isReminderIOASenderView = (ioApproverEmail === userEmail) && this.props.receiverDetails.isSkippedIOApproval === false && isOALStage;

      let disableCAR = !this.props.isSuperAdmin && this.props.isExistingPPCBA &&
        ['awaiting approval'].includes(this.props.basicDetails.overallStatus.toLowerCase());        
      return (
        <>
          <div className="projectEntry-container">
            <div className={`${this.props.showClientStory ? 'backdrop-cs-modal' : ''}`}></div>
            <CVMToasterNotif />
            <div className="title-section">
              <h4 className="header24-semibold-midnight">Project Data Entry</h4>
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => {
                  this.props.onCloseProjectDataEntry();
                }}
              />
            </div>
            {this.props.basicDetails.overallStatus === 'Rejected' ? (
              <div className="errorbox">
                This Project Data Entry has been rejected by {this.props.receiverDetails.rejectedBy}
              </div>
            ) : (
              ''
            )}
            {this.props.isExistingDataEntry ? (
              <div className="errorbox">
                Please note: Previously approved data is already populated on the screen for your reference.You need to report actual values going forward.
              </div>
            ) : (
              ''
            )}
            {!this.props.mainSelectedProject.projectDetails.isOMIDContractIDExisting ? (
              <div className="errorbox">
                Contract Id & OMID are not saved for project. Please navigate to Project Configuration page to update the mandatory details.
              </div>
            ) : (
              ''
            )}
            {
              this.props.isValidMappedFields.mappedFieldErrMsgs.map(err => {
                return (
                  <div className="errorbox">
                    {err}
                  </div>
                )
              })
            }     
            {this.props.anyInvalidEmails && (
              <div className="errorbox">
                One or more EIDs mapped to project are invalid. Please navigate to Program & Project configuration to update invalid EIDs.
              </div>
            )}
            <div className="projectEntry-subcontainer">
              <div className="btn-container">
                <div className="project-title">Project: {this.props.basicDetails.projectName}</div>
                <div className="report-prd">
                  <div className="rvd-link">
                    <img
                      src={ppcIcon}
                      alt="PPC Link"
                    />
                    Go to <span onClick={this.props.setRedirectModal}> Project Configuration</span> Module
                  </div>
                  Modified Date:
                  <mark
                    className={
                      this.props.showRepPerHighlighted
                        ? 'reporting-period-highlight'
                        : 'reporting-period-normal'
                    }
                  >
                    {`${modifiedDate.toUpperCase()}`}
                  </mark>
                </div>
              </div>

              <Accordion className="configure-client-collapse">
                <Card id="configCardID">
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      {Object.keys(this.props.basicDetails).length > 0 &&
                        this.props.basicDetails.deliveredBy !== '' ? (
                        <div className="row">
                          <div className="program-details-row col-lg-4">
                            <div className="program-details-column">
                              <div className="title">Program Name</div>
                              <div className="value" title={this.props.basicDetails.programName}>
                                {this.props.basicDetails.programName}
                              </div>
                            </div>
                            <div className="program-details-column">
                              <div className="title">Project Creator</div>
                              <div className="value" title={this.props.basicDetails.projectCreator}>
                                {this.props.basicDetails.projectCreator}
                              </div>
                            </div>
                            <div className="program-details-column">
                              <div className="title">CX Lead</div>
                              <div className="value" title={this.props.basicDetails.cxLead}>
                                {this.props.basicDetails.cxLead}
                              </div>
                            </div>
                            <div className="program-details-column">
                              <div className="title">Business Advisor</div>
                              <div className="value" title={BAdvisors.length > 0 ? String(BAdvisors.join(',')) : ''}>
                                {BAdvisors.length > 0 ? String(BAdvisors.join(',')) : ''}
                              </div>
                            </div>
                          </div>
                          <div className="program-details-row col-lg-4">
                            <div className="program-details-column">
                              <div className="title">Offering</div>
                              <div className="value" title={this.props.basicDetails.offeringName}>
                                {this.props.basicDetails.offeringName}
                              </div>
                            </div>
                            <div className="program-details-column">
                              <div className="title">Delivery Location</div>
                              <div className="value" title={this.props.basicDetails.deliveryCenter}>
                                {this.props.basicDetails.deliveryCenter}
                              </div>
                            </div>
                            <div className="program-details-column">
                              <div className="title">IO Approver</div>
                              <div className="value" title={this.props.basicDetails.ioApprover}>
                                {this.props.basicDetails.ioApprover}
                              </div>
                            </div>
                            {/*PBI 197930 */}
                            <div className="program-details-column car">
                              <div className="title">Client Approver Role*</div>
                              {/* <div className="car-body"> */}
                              {(isBA || isSuperAdmin || isPPC) ? (
                                <a data-tip data-for="businessAdvisor">
                                  <div className="client-approver-role" >
                                    <GrooveSelectMenuBasic
                                      name="clientApproverRole"
                                      placeholder=""
                                      list={this.props.clientApproverRoleList}
                                      defaultData={this.props.clientApprover.clientApproverRole}
                                      id="clientApproverRole"
                                      callbackFunction={(e) => this.props.handleSelectClientApproverRole(e)}
                                      // isRequired={true}
                                      customClassName={
                                        this.props.clientApprover.clientApproverRoleEmpty
                                          ? 'empty-err'
                                          : ''
                                      }
                                      isDisabled={disableCAR || this.props.RVDComponents.rvdParams || disableSuperAdmin ||(!this.props.isSuperAdmin&&this.props.basicDetails.overallStatus=='Approved')}
                                    />
                                  </div>
                                </a>
                              ) : (
                                <div className="value" title={this.props.clientApprover.clientApproverRole.label}>
                                  {this.props.clientApprover.clientApproverRole.label}
                                </div>
                              )}
                              {/* </div> */}

                              {/* {this.props.clientApprover.clientApproverRole.label !== 'Others'
                                && (this.props.RVDComponents.rvdParams || disableSuperAdmin) ? */}
                              {this.props.clientApprover.clientApproverRole !== "" ?
                                <ReactTooltip id="businessAdvisor" type="dark" effect="solid">
                                  <span>
                                    {this.props.clientApprover.clientApproverRole.label}
                                  </span>
                                </ReactTooltip>
                                : ('')
                              }
                              {this.props.clientApprover.clientApproverRoleEmpty ?
                                (
                                  <div className='error-message'>Missing Required field </div>
                                ) : ("")}
                            </div>
                          </div>
                          <div className="program-details-row col-lg-4">
                            <div className="program-details-column">
                              <div className="title">Sub-Offerings</div>
                              <div className="value" title={subOfferings}>
                                {subOfferings}
                              </div>
                            </div>
                            <div className="program-details-column">
                              <div className="title">Data Submitter</div>
                              <div className="value" title={this.props.basicDetails.dataSubmitter}>
                                {this.props.basicDetails.dataSubmitter}
                              </div>
                            </div>
                            <div className="program-details-column">
                              <div className="title">Operations Account Lead</div>
                              <div className="value" title={opeAccLead}>
                                {opeAccLead}
                              </div>
                            </div>
                            {/*Others */}
                            {this.props.clientApprover.clientApproverRole.label === 'Others' ? (
                              <div className="program-details-column">
                                <div className="title">If Others, specify*</div>
                                {(isBA || isSuperAdmin || isPPC) ? (
                                  <a data-tip data-for="businessAdvisor-others">
                                    <div className="client-approver-role" >
                                      <GrooveInput
                                        maxLength={150}
                                        id="client-approver-role-textbox"
                                        name="client-approver-role-textbox"
                                        type="text"
                                        placeholder=""
                                        inputValue={this.props.clientApprover.otherSpecify}
                                        callbackFunction={(e) => this.props.handleOtherClientApproverRole(e)}
                                        customClassName={
                                          `${this.props.clientApprover.otherSpecifyEmpty && this.props.clientApprover.clientApproverRole.label === 'Others' ? 'empty-err' : ''} ${this.props.invalidOtherClientApprovalRole ? 'empty-err': ''}`}
                                        // isRequired={true}
                                        isDisabled={this.props.RVDComponents.rvdParams || disableSuperAdmin}

                                      />
                                    </div>
                                  </a>
                                ) : (
                                  <div className="value" title={this.props.clientApprover.otherSpecify}>
                                    {this.props.clientApprover.otherSpecify}
                                  </div>
                                )}
                                {this.props.clientApprover.clientApproverRole.label === 'Others' &&
                                  this.props.clientApprover.otherSpecify !== "" ?
                                  <ReactTooltip id="businessAdvisor-others" type="dark" effect="solid">
                                    <span>
                                      {this.props.clientApprover.otherSpecify}
                                    </span>
                                  </ReactTooltip>
                                  : ('')
                                }
                                {this.props.clientApprover.otherSpecifyEmpty && this.props.clientApprover.clientApproverRole.label === 'Others' ?
                                  (
                                    <div className='error-message'>Missing Required field </div>
                                  ) : this.props.invalidOtherClientApprovalRole ? 
                                  <div className='error-message'>Invalid text</div>
                                  : ("")}
                              </div>
                            ) : ("")}
                          </div>
                        </div>
                      ) : (
                        <div className="emptyProject-container">
                          <div className="empty-program-container bodytext18-regular-slate">
                            <img src={addfiles} alt="select client" />
                            <span className="bodytext24-medium-slate">
                              No Project Selected for Data Entry
                            </span>
                            Please select project to view its basic details & its respective BOI,
                            Dimension & Subdimension
                          </div>
                        </div>
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      <div className="bodytext18-medium-midnight">Basic Details</div>
                      <GrooveIconSvg
                        id="basicDetailsExpand"
                        customClassName="accordion-button"
                        size="large"
                        name="chevron-down"
                        iconStyle="solid"
                        iconColor="stat-neutral"
                      />
                    </Accordion.Toggle>
                  </Card.Header>
                </Card>
              </Accordion>
              <ClientStoryPanel
                onCloseCSAttachmentError={this.props.onCloseCSAttachmentError}
                hasNoCSAttachment={this.props.hasNoCSAttachment}
                hasOldClientStoryFileError={this.props.hasOldClientStoryFileError}
                inValidEditorFields={this.props.inValidEditorFields}
                changeInvalidEditorFields={this.props.changeInvalidEditorFields}
                editedAnyFieldOnce={this.props.editedAnyFieldOnce}
                setEditedAnyFieldOnce={this.props.setEditedAnyFieldOnce}
                checkForInvalidEmailsOnLanding={this.props.checkForInvalidEmailsOnLanding}
              />
              <div className="reports-boiHistory-container">
                <span
                  className="top-left-container-text"
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.props.handleShowFileHistory(true)}
                >
                  <img src={historyIcon} alt="comment" className="top-left-container-icon" />
                  File History
                </span>
              </div>
              <ClientAlignmentPanel
                onCloseCAAttachmentError={this.props.onCloseCAAttachmentError}
                hasNoCAAttachment={this.props.hasNoCAAttachment}
                editedAnyFieldOnce={this.props.editedAnyFieldOnce}
                setEditedAnyFieldOnce={this.props.setEditedAnyFieldOnce}
                checkForInvalidEmailsOnLanding={this.props.checkForInvalidEmailsOnLanding}
              />
              <div className="sub-title">
                <Row className="sub-title-header">
                  <div className="rvd-dimension-title">
                    <h5>Project Dimensions ({this.props.basicDetails.noOfDimensions})</h5>
                  </div>
                  {this.showRefresh() && (
                    <OverlayTrigger
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Tooltip id="rvd-refresh-tooltip-bottom">
                          Click to see newly added BOIs in RVD
                        </Tooltip>
                      }
                    >
                      <div className="rdv-refresh-list">
                        <img className="comment-icon" src={play} alt="refresh" />
                        <GrooveButton
                          id="primry-btn-1"
                          hasIcon={false}
                          type=""
                          colorClass=""
                          text="View Refreshed List"
                          callbackFunction={this.props.handleFetchUpdatedBOI}
                          customClassName="refreshList-button"
                          iconAlignment="right"
                          iconSize="small"
                          iconName="comment"
                          iconStyle="regular"
                          iconId="btn-icon-01"
                        />
                      </div>
                    </OverlayTrigger>
                  )}
                </Row>
              </div>
              {this.props.basicDetails.overallStatus !== '' &&
                this.props.basicDetails.overallStatus !== undefined ? (
                <div className="reports-boiHistory-container">
                  <span
                    className="top-left-container-text"
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.props.history.push('/DataIngestion/boihistory')}
                  >
                    <img src={historyIcon} alt="comment" className="top-left-container-icon" />
                    BOI History
                  </span>
                </div>
              ) : (
                ''
              )}

              {/*BOIs */}
              {tempSortprojectDimensionsList.map((dItem, dIndex) => {
                let that = this;
                if (dItem.dimensionID === 1) {
                  return (
                    <ClientValueIdentified
                      {...this.props}
                      dItem={dItem}
                      dIndex={dIndex}
                      isIOAdmin={isIOAdmin}
                      isExistingFinancial={isExistingFinancial}
                      disableSuperAdmin={disableSuperAdmin}
                      handleParameterChange={this.props.handleParameterChange}
                      formatValue={formatValue}
                      isCreatorView={isCreatorView}
                      isReminderSenderView={isReminderSenderView}
                      isReminderCXLOALSenderView={isReminderCXLOALSenderView}
                      isReminderCXLIOASenderView={isReminderCXLIOASenderView}
                      isReminderIOASenderView={isReminderIOASenderView}
                      isCXLStage={isCXLStage}
                      editedAnyFieldOnce={this.props.editedAnyFieldOnce}
                      setEditedAnyFieldOnce={this.props.setEditedAnyFieldOnce}
                      checkForInvalidEmailsOnLanding={this.props.checkForInvalidEmailsOnLanding}
                      overallStatus={this.props.basicDetails.overallStatus}
                    />
                  );
                }
                return (
                  <div>
                    <div className="header18-semibold-midnight">{dItem.dimensionName}</div>
                    {this.props.showNotifMessageArr[
                      dItem.dimensionName.replace(/\s/g, '').replace(/&/g, '').toLowerCase()
                    ] ? (
                      <div className="disclaimer-message rvd-disclaimer-msg">
                        <GrooveIconSvg
                          customClassName="accordion-button"
                          size="small"
                          name="exclamation-triangle"
                          iconStyle="solid"
                          iconColor="stat-warning"
                        />
                        BOIs with $ UOM selected under Financial Dimension will be Considered as
                        client P&L Sign-Off. $ value under all other Dimensions except Financial
                        Dimension, will not be considered as Client P&L Sign-Off.
                      </div>
                    ) : (
                      ''
                    )}
                    {dIndex === 0 ? (
                      <div className="accordion-action-btns">
                        {
                          isReminderSenderView ?
                            <CVMSendReminderButton tooltipText={"Send reminder for project approval"} onSendReminder={this.props.onSendReminder} isDisabledReminderButton={this.props.isDisabledReminderButton} /> :
                            isReminderCXLOALSenderView ?
                              <CVMSendReminderButton tooltipText={"Send reminder for project approval"} onSendReminder={this.props.onSendReminder} isDisabledReminderButton={this.props.isDisabledReminderButton} /> :
                              isReminderCXLIOASenderView ?
                                <CVMSendReminderButton tooltipText={"Send reminder for project approval"} onSendReminder={this.props.onSendReminder} isDisabledReminderButton={this.props.isDisabledReminderButton} /> : isReminderIOASenderView ?
                                  <CVMSendReminderButton tooltipText={"Send reminder for project approval"} onSendReminder={this.props.onSendReminder} isDisabledReminderButton={this.props.isDisabledReminderButton} /> : ""
                        }
                        <GrooveButton
                          id={'expandAll' + dIndex}
                          hasIcon={false}
                          type=""
                          colorClass=""
                          text="Expand All"
                          customClassName={
                            this.props.activeExpand ? 'expandAll-button active' : 'expandAll-button'
                          }
                          iconAlignment="right"
                          iconSize="small"
                          iconName="plus"
                          iconStyle="solid"
                          iconId="btn-icon-01"
                          callbackFunction={(e) => this.props.onExpandCollapse(e, dIndex)}
                        />
                        <GrooveButton
                          id={'collapseAll' + dIndex}
                          hasIcon={false}
                          type=""
                          colorClass=""
                          text="Collapse All"
                          callbackFunction={(e) => this.props.onExpandCollapse(e, dIndex)}
                          customClassName={
                            this.props.activeCollapse
                              ? 'collapseAll-button active'
                              : 'collapseAll-button'
                          }
                          iconAlignment="right"
                          iconSize="small"
                          iconName="plus"
                          iconStyle="solid"
                          iconId="btn-icon-01"
                        />
                      </div>
                    ) : (
                      ''
                    )}
                    {dItem.subDimensionList.map((SDItem, SDIndex) => {
                      return (
                        <Accordion
                          className="subdimension-section-collapse"
                          defaultActiveKey={isIOAdmin ? (isExistingFinancial ? '0' : '1') : '0'}
                          onSelect={(e) => {
                            this.props.onSelectAccordion(e);
                          }}
                        >
                          <Card>
                            <Accordion.Collapse
                              eventKey={isIOAdmin && isExistingFinancial ? dIndex.toString() : '0'}
                            >
                              <Card.Body>
                                {SDItem.boiList.map((boiItem, boiIndex) => {
                                  if (boiItem.isZeroTarget) {
                                    isAgnosticTargetZero = true;
                                  }
                                  const itemId = `${dIndex}_${SDIndex}_${boiIndex}_${boiItem.boiUOM}`;
                                  const hadFinalized = HasValue(boiItem.boiApprovedRealizedValue);
                                  const stat = this.props.basicDetails.overallStatus.toLowerCase();
                                  const isAd = this.props.isSuperAdmin;
                                  const isApprovedAndYetToEdit =
                                    'approved' === stat && !this.props.hadCompute;
                                  const colInputValues =
                                    ['approved', 'in progress', ''].indexOf(stat) > -1
                                      ? 2
                                      : stat === 'awaiting approval' ||
                                        (stat === 'rejected' &&
                                          !IsValidStr(boiItem.boiRejectionComment))
                                        ? 1
                                        : 2;
                                  const wVisible =
                                    (stat === 'awaiting approval' ||
                                      stat === 'approved' ||
                                      stat === 'in progress') &&
                                      hadFinalized
                                      ? this.props.RVDComponents.approveRejectButton &&
                                        stat === 'awaiting approval'
                                        ? 2
                                        : 1
                                      : !hadFinalized
                                        ? 1
                                        : 2;
                                  const boiCom = 1;
                                  const awaitingApprovalUserRoleID12 =
                                    stat === 'awaiting approval' &&
                                    !([].indexOf(currentUserRoleID) === -1);
                                  const rejectedStat = stat === 'rejected';
                                  const colWidth = {
                                    boi: awaitingApprovalUserRoleID12 ? 2 : 3,
                                    targetValue: awaitingApprovalUserRoleID12
                                      || stat === 'awaiting approval'
                                      ? 2
                                      : rejectedStat
                                        ? 2
                                        : hadFinalized
                                          ? 1
                                          : colInputValues,
                                    realizedValueApp: awaitingApprovalUserRoleID12
                                      ? 2
                                      : rejectedStat
                                        ? 1
                                        : colInputValues,
                                    realizedValueNew: awaitingApprovalUserRoleID12
                                      ? 2
                                      : rejectedStat
                                        ? 1
                                        : colInputValues,
                                    targetRealizeValue: awaitingApprovalUserRoleID12
                                      ? 2
                                      : rejectedStat
                                        ? 1
                                        : colInputValues,
                                    wheelVisibility:
                                      (rejectedStat && !hadFinalized)
                                        || (['approved', 'in progress', ''].indexOf(stat) > -1 && !hadFinalized)
                                        ? 2
                                        : rejectedStat && hadFinalized
                                          || (['approved', 'in progress', ''].indexOf(stat) > -1 && hadFinalized)
                                          ? 1
                                          : wVisible,
                                    boiComment: awaitingApprovalUserRoleID12 ? 2 : boiCom,
                                  };
                                  const colStatus =
                                    rejectedStat && !hadFinalized
                                      ? 'rejected'
                                      : rejectedStat && hadFinalized
                                        ? 'rejected-new'
                                        : stat === 'awaiting approval' && hadFinalized
                                          ? 'awapp-new'
                                          : stat === 'awaiting approval' && !hadFinalized
                                            ? 'awapp'
                                            : stat === 'approved' && hadFinalized && 'approved';

                                  return (
                                    <div key={boiIndex} className="rvd-subdimension-table-wrap">
                                      <div className="subdimension-table rvd-subdimension-table">
                                        <div className="subdimension-header">
                                          <div className="row">
                                            <div className={`col-sm-${colWidth.boi} non-usd`}>
                                              Business Outcome Indicator
                                            </div>
                                            <div className={`col-sm-${colWidth.targetValue} non-usd`}>
                                              Target Value
                                            </div>
                                            {hadFinalized && (
                                              <div
                                                className={`col-sm-${colWidth.realizedValueApp} non-usd`}
                                              >
                                                Realized Value (Approved)
                                              </div>
                                            )}

                                            <div className={`col-sm-${colWidth.realizedValueNew} non-usd`}>
                                              Realized Value{hadFinalized && ` (New)`}
                                            </div>
                                            <div
                                              className={`col-sm-${colWidth.targetRealizeValue} target-realized-${colStatus} non-usd`}
                                            >
                                              Target Value - Realized Value{' '}
                                              {hadFinalized && ` (New)`}
                                            </div>
                                            <div
                                              className={`col-sm-${colWidth.wheelVisibility} wVis-${colStatus} dashboard-visibility-rvd non-usd`}
                                            >
                                              Dashboard Visibility
                                              <a data-tip data-for="sadFace">
                                                <div className="info-icon">
                                                  <GrooveIconSvg
                                                    size="large"
                                                    name="info-circle"
                                                    iconStyle="solid"
                                                    iconColor="stat-alternate"
                                                  />
                                                </div>
                                              </a>
                                              <ReactTooltip id="sadFace" type="dark" effect="solid">
                                                <span>
                                                  Show this BOI on the Value Meter Dashboard?
                                                </span>
                                              </ReactTooltip>
                                            </div>
                                            <div
                                              className={`col-sm-${colWidth.boiComment} boiComm-${colStatus} non-usd-boi`}
                                            >
                                              BOI Comment
                                            </div>
                                            {stat === 'awaiting approval' &&
                                              !this.props.RVDComponents.approveRejectButton && (
                                                <div className={`col-sm-${(stat === 'awaiting approval' && (hadFinalized || !hadFinalized)) ? '2' : '3'} app-rej-${colStatus} non-usd`}>
                                                  Approver Section
                                                </div>
                                              )}
                                            {stat === 'rejected' && (
                                              <div className={`col-sm-2 pde-comment-${colStatus} non-usd`}>
                                                Rejection Comment
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="subdimension-body">
                                          <Accordion
                                            className="subdimension-row"
                                            defaultActiveKey="0"
                                          >
                                            <Card
                                              className={`${boiItem.projectLevelBOIStatus.toLowerCase() !==
                                                'rejected'
                                                ? 'approved-project-level'
                                                : 'rejected-project-level'
                                                } ${boiItem.boiStatus === 'Rejected' ? 'error-card' : ''
                                                }`}
                                            >
                                                  <Accordion.Collapse eventKey="0">
                               
                                                                  <Card.Body>
                                                  <div className="bodytext14-medium">
                                                    Parameters
                                                  </div>
                                                  {boiItem.parameterList
                                                    .sort((a, b) => {
                                                      if (boiItem.IsCustom) {
                                                        return a.parameterCustomCode.localeCompare(
                                                          b.parameterCustomCode,
                                                          undefined,
                                                          {
                                                            numeric: true,
                                                            sensitivity: 'base',
                                                          }
                                                        );
                                                      } else {
                                                        return a.parameterCode.localeCompare(
                                                          b.parameterCode,
                                                          undefined,
                                                          {
                                                            numeric: true,
                                                            sensitivity: 'base',
                                                          }
                                                        );
                                                      }
                                                    })
                                                    .map((paramItem, paramIndex) => {
                                                      return (
                                                        <div key={paramIndex}>
                                                          <div className="parameter-field">
                                                            <div className="field-text">
                                                              {paramItem.parameterName}
                                                            </div>
                                                            <div className="field-value">
                                                              <input
                                                                type="text"
                                                                id="text1"
                                                                style={
                                                                  (paramItem.isInvalid
                                                                    ?  {border: '1px solid red'}
                                                                    : 
                                                                    disableSuperAdmin
                                                                      ? {
                                                                        cursor: 'not-allowed',
                                                                        backgroundColor: '#E8E8E8',
                                                                      }
                                                                      : {}
                                                                      )
                                                                }
                                                                name="text1"
                                                                placeholder="Enter Value"
                                                                value={
                                                                  paramItem.parameterValue === null
                                                                    ? ''
                                                                    : paramItem.parameterValue
                                                                }
                                                                onBlur={(e) => {
                                                                  if (!this.props.editedAnyFieldOnce && this.props.basicDetails.overallStatus === 'Approved') {
                                                                    this.props.checkForInvalidEmailsOnLanding();
                                                                    this.props.setEditedAnyFieldOnce(true);
                                                                  }
                                                                   that.props.handleParameterChange(
                                                                    {
                                                                      dimension: {
                                                                        item: dItem,
                                                                        index: dIndex,
                                                                      },
                                                                      subDimension: {
                                                                        item: SDItem,
                                                                        index: SDIndex,
                                                                      },
                                                                      boi: {
                                                                        item: boiItem,
                                                                        index: boiIndex,
                                                                      },
                                                                      parameter: {
                                                                        item: paramItem,
                                                                        index: paramIndex,
                                                                      },
                                                                    },
                                                                    e.target.value,
                                                                    'blur'
                                                                  );
                                                                }}
                                                                onChange={(e) => {
                                                                  if (!this.props.editedAnyFieldOnce && this.props.basicDetails.overallStatus === 'Approved') {
                                                                    this.props.checkForInvalidEmailsOnLanding();
                                                                    this.props.setEditedAnyFieldOnce(true);

                                                                  }
                                                                  const isComputeDisabled = e.target.value === "" || e.target.value === null;
                                                                  this.props.setIsComputeDisabled(isComputeDisabled);

                                                                  that.props.handleParameterChange(
                                                                    {
                                                                      dimension: {
                                                                        item: dItem,
                                                                        index: dIndex,
                                                                      },
                                                                      subDimension: {
                                                                        item: SDItem,
                                                                        index: SDIndex,
                                                                      },
                                                                      boi: {
                                                                        item: boiItem,
                                                                        index: boiIndex,
                                                                      },
                                                                      parameter: {
                                                                        item: paramItem,
                                                                        index: paramIndex,
                                                                      },
                                                                    },
                                                                    e.target.value,

                                                                  );
                                                                }}
                                                                disabled={
                                                                  this.props.RVDComponents
                                                                    .rvdParams || disableSuperAdmin || !this.props.isSuperAdmin&&this.props.basicDetails.overallStatus=='Approved'
                                                                }
                                                                maxLength={255}
                                                              />{' '}
                                                              {paramItem.parameterUOM}
                                                              {(paramItem.parameterValue&&paramItem.parameterValue.length>=12) && (
                                                                <div className="param-char-length-err">
                                                                  {'You have reached your maximum limit of characters allowed.'}
                                                                </div>
                                                              )}
                                                               {(paramItem.isInvalid&&(paramItem.parameterValue==null ||paramItem.parameterValue=="")) && (
                                                                <div className="param-char-length-err">
                                                                  {'Missing Required Field'}
                                                                </div>
                                                              )}
                                                              {
                                                                (paramItem.isInvalid) && (paramItem.invalidMsg=="Invalid Input")&&(
                                                                  <div className="param-char-length-err">
                                                                    {paramItem.invalidMsg}
                                                                  </div>
                                                                )
                                                              }
                                                            </div>
                                                          </div>
                                                        </div>
                                                      );
                                                    })}
                                                  {boiItem.projectLevelBOIStatus.toLowerCase() ===
                                                    'rejected' && (
                                                      <div className="rejected-projBoi-warning">
                                                        <GrooveIconSvg
                                                          className="exclamation-icon"
                                                          size="normal"
                                                          name="exclamation"
                                                          iconStyle="solid"
                                                        />
                                                        <div>
                                                          This BOI is rejected at Program/Project
                                                          configuration level and values reported will
                                                          neither be shown in the wheel nor will be
                                                          included for Reporting.
                                                        </div>
                                                      </div>
                                                    )}
                                                </Card.Body>
                              </Accordion.Collapse>
                                              <Card.Header>
                                                <Accordion.Toggle
                                                  as={Button}
                                                  variant="link"
                                                  eventKey="0"
                                                >
                                                  <GrooveIconSvg
                                                    customClassName="accordion-button"
                                                    size="tiny"
                                                    name="chevron-right"
                                                    iconStyle="solid"
                                                    iconColor="stat-neutral"
                                                  />
                                                </Accordion.Toggle>
                                                <div className="row bodytext14">
                                                  <div className={`col-sm-${colWidth.boi}`}>
                                                    {boiItem.boiName}
                                                    <OverlayTrigger
                                                      key="right"
                                                      placement="auto"
                                                      overlay={
                                                        <Tooltip id="boi-tooltip-right">
                                                          <div className="boi-details-wrap row">
                                                            <div class="col-md-6">
                                                              <h6>Offering</h6>
                                                              <p class="">
                                                                {boiItem.offeringName
                                                                  ? boiItem.offeringName
                                                                  : ''}
                                                              </p>
                                                              <h6>Dimension</h6>
                                                              <p class="">{dItem.dimensionName}</p>
                                                              <h6>BOI Description</h6>
                                                              <p class="">
                                                                {boiItem.boiDescription}
                                                              </p>
                                                            </div>
                                                            <div class="col-md-6">
                                                              <h6>Sub-Offering</h6>
                                                              <p class="">
                                                                {boiItem.subofferingName
                                                                  ? boiItem.subofferingName
                                                                  : ''}
                                                              </p>
                                                              <h6>Sub-dimension</h6>
                                                              <p class="">
                                                                {SDItem.subDimensionname}
                                                              </p>
                                                              <h6>Leading Practice</h6>
                                                              <p class="">
                                                                {boiItem.leadingIndicator}
                                                              </p>

                                                              <table>
                                                                <tr class="col-md-12">
                                                                  <th class="col-md-6">
                                                                    <h6>Parameter</h6>
                                                                  </th>
                                                                  <th class="col-md-6">
                                                                    <h6>Unit of Measure</h6>
                                                                  </th>
                                                                </tr>
                                                                {(boiItem.hasOwnProperty(
                                                                  'parameterList'
                                                                )
                                                                  ? boiItem.parameterList
                                                                  : []
                                                                ).map((params) => {
                                                                  return (
                                                                    <tr>
                                                                      <td class="col-md-6">
                                                                        <p>
                                                                          {params.parameterName}
                                                                        </p>
                                                                      </td>
                                                                      <td class="col-md-6">
                                                                        <p>{params.parameterUOM}</p>
                                                                      </td>
                                                                    </tr>
                                                                  );
                                                                })}
                                                              </table>
                                                            </div>
                                                          </div>
                                                        </Tooltip>
                                                      }
                                                    >
                                                      <div className="rvdboi-new-info">
                                                        {!boiItem.IsCustom && (
                                                          <img
                                                            className="new-info-icon"
                                                            src={newInfo}
                                                            alt="info"
                                                          />
                                                        )}
                                                      </div>
                                                    </OverlayTrigger>
                                                    {boiItem.IsCustom && (
                                                      <span>
                                                        <button
                                                          className="custom-boi"
                                                          style={{ marginLeft: '1em' }}
                                                          onClick={() =>
                                                            this.props.onClickCustomBOI(
                                                              boiItem.boiID,
                                                              true
                                                            )
                                                          }
                                                        >
                                                          Custom
                                                        </button>
                                                      </span>
                                                    )}
                                                    <div
                                                      className={`projectboi-rejected-alert ${boiItem.IsCustom ? 'custom-with-alert' : ''
                                                        }`}
                                                    >
                                                      {boiItem.projectLevelBOIStatus.toLowerCase() ===
                                                        'rejected' && (
                                                          <OverlayTrigger
                                                            key="bottom"
                                                            placement="bottom"
                                                            overlay={
                                                              <Tooltip id="rvd-disabled-sys">
                                                                Value reported will not be reflected
                                                                in the dashboard as this BOI is
                                                                rejected at program/project level.
                                                              </Tooltip>
                                                            }
                                                          >
                                                            <div>
                                                              <GrooveIconSvg
                                                                customClassName="dark-red-alert"
                                                                size="small"
                                                                name="exclamation-triangle"
                                                                iconStyle="solid"
                                                                iconColor="stat-alternate"
                                                              />
                                                            </div>
                                                          </OverlayTrigger>
                                                        )}
                                                    </div>
                                                </div>
                                                  <div
                                                    className={`col-sm-${colWidth.targetValue} target-value-padding targetValue-${colStatus}`}
                                                    style={
                                                      boiItem.isZeroTarget && { display: 'block' }
                                                    }
                                                  >
                                                    <div
                                                      className={
                                                        boiItem.isZeroTarget &&
                                                        boiItem.boiTarget === 0 &&
                                                        'zero-target-value'
                                                      }
                                                    >
                                                      {formatValue(
                                                        `${itemId}_TA`,
                                                        boiItem.isAgnosticBOI
                                                          ? boiItem.boiTargetApproved
                                                          : boiItem.boiTargetApproved === null
                                                            ? boiItem.boiTarget
                                                            : boiItem.boiTargetApproved,
                                                        boiItem.boiUOM
                                                      )}
                                                    </div>
                                                    <div
                                                      className={
                                                        boiItem.isZeroTarget &&
                                                        boiItem.boiTarget === 0 &&
                                                        'zero-target-value-msg'
                                                      }
                                                    >
                                                      {boiItem.zeroTargetMessage}
                                                    </div>
                                                  </div>
                                                  {boiItem.boiApprovedRealizedValue !== null && (
                                                    <div
                                                      className={`col-sm-${colWidth.realizedValueApp} realized-value-${colStatus}`}
                                                    >
                                                      {formatValue(
                                                        `${itemId}_ARV`,
                                                        boiItem.boiApprovedRealizedValue,
                                                        boiItem.boiUOM
                                                      )}
                                                    </div>
                                                  )}
                                                  <div
                                                    className={`col-sm-${colWidth.realizedValueNew} realizedValue-new-${colStatus}`}
                                                  >
<span
  className={`${TryGetObjValueAlt(
    boiItem,
    false,
    'realizedValInvalid'
  ) && 'rvd-invalid-realizedval'}`}
>

 

 
      {formatValue(
        `${itemId}_RV`,
        isApprovedAndYetToEdit
          ? null
          : boiItem.boiRealizedValue,
          boiItem.boiUOM
      )}
  
  
</span>

                                                  </div>
                                                  <div
                                                    className={`col-sm-${colWidth.targetRealizeValue} target-realized-${colStatus}`}
                                                  >
                                                    {formatValue(
                                                      `${itemId}_VD`,
                                                      boiItem.boiValueDiff,
                                                      boiItem.boiUOM
                                                    )}
                                                  </div>
                                                  <div
                                                    className={`col-sm-${colWidth.wheelVisibility} wVis-${colStatus}`}
                                                  >
                                                    <GrooveSwitchToggle
                                                      id={'switch-tgl-01' + boiIndex}
                                                      toggle_labels=""
                                                      defaultValue={
                                                        boiItem.projectBOIWheelVisibility ===
                                                          true || boiItem.boiWheelVisivility === 1
                                                          ? true
                                                          : false
                                                      }
                                                      isDisabled={true}
                                                      customClassName="wheel-visibility-button disabled-switch"
                                                    />
                                                  </div>
                                                  <div
                                                    className={`col-sm-${colWidth.boiComment} boiComm-${colStatus}`}
                                                  >
                                                    <GrooveIconSvg
                                                      customClassName="commentary-modal-btn"
                                                      size="large"
                                                      name="comment"
                                                      iconStyle={
                                                        boiItem.RVDCommentHistory.length > 0
                                                          ? 'solid'
                                                          : 'regular'
                                                      }
                                                      iconColor={
                                                        this.props.isComment
                                                          ? boiItem.RVDCommentHistory.length > 0
                                                            ? 'stat-alternate'
                                                            : 'stat-warning'
                                                          : 'stat-alternate'
                                                      }
                                                      callbackFunction={() =>
                                                        !isPPC && !isBA && !isSuperAdmin ?
                                                          this.props.handleBOICommentHistory(
                                                            true,
                                                            boiItem,
                                                            SDItem.boiList
                                                          )
                                                          : this.props.RVDComponents.approveRejectButton ||
                                                            boiItem.isNewValue ||
                                                            (isPPC || isBA || isSuperAdmin)
                                                            ? this.props.handleBOIComment(true, boiItem)
                                                            : this.props.handleBOICommentHistory(true, boiItem, SDItem.boiList)
                                                      }
                                                    />
                                                  </div>
                                                  {((this.props.basicDetails.overallStatus.toLowerCase() ===
                                                    'awaiting approval' &&
                                                    !this.props.RVDComponents
                                                      .approveRejectButton) ||
                                                    this.props.basicDetails.overallStatus.toLowerCase() ===
                                                    'rejected') && (
                                                      <div
                                                        className={`col-sm-2 app-rej-${colStatus}`}
                                                      >
                                                        <div className="rvd-rejection-comment">
                                                          {this.props.basicDetails.overallStatus.toLowerCase() ===
                                                            'awaiting approval' &&
                                                            !this.props.RVDComponents
                                                              .approveRejectButton && (
                                                              <ApproveRejectRadioButton
                                                                boiID={boiItem.boiID}
                                                                boiStatus={boiItem.boiStatus}
                                                                dIndex={dIndex}
                                                                SDIndex={SDIndex}
                                                                boiIndex={boiIndex}
                                                                isRejected={this.props.setIsReject}
                                                                userRoleID={
                                                                  this.props.currentUserRoleID
                                                                }
                                                                dimensionName={dItem.dimensionName}
                                                                boiUOM={boiItem.boiUOM}
                                                                currentUserRoleID={
                                                                  this.props.currentUserRoleID
                                                                }
                                                                isExistingIO={this.props.isExistingIO}
                                                              />
                                                            )}
                                                          {boiItem.boiStatus === 'Rejected' && (
                                                            <GrooveTextBox
                                                              maxLength={250}
                                                              id="comment-textbox-rejected"
                                                              name="comment-textbox-rejected"
                                                              type="text"
                                                              placeholder="Rejection Comment*"
                                                              statusMessage={
                                                                this.props.submitReject
                                                                  ? boiItem.boiReason === ''
                                                                    ? 'Reject Reason is required'
                                                                    : null
                                                                  : null
                                                              }
                                                              isValid={
                                                                this.props.basicDetails.overallStatus.toLowerCase() ===
                                                                  'rejected'
                                                                  ? false
                                                                  : boiItem.boiReason !== ''
                                                              }
                                                              customClassName={
                                                                this.props.submitReject
                                                                  ? boiItem.boiReason === ''
                                                                    ? 'input-invalid'
                                                                    : null
                                                                  : null
                                                              }
                                                              textValue={boiItem.boiReason}
                                                              callbackFunction={(e) =>
                                                                this.props.updateReason(e, {
                                                                  dimensionIndex: dIndex,
                                                                  subDimensionIndex: SDIndex,
                                                                  boiIndex: boiIndex,
                                                                })
                                                              }
                                                              isDisabled={
                                                                this.props.basicDetails.overallStatus.toLowerCase() !==
                                                                'awaiting approval'
                                                              }
                                                            />
                                                          )}
                                                        </div>
                                                      </div>
                                                    )}
                                                </div>
                                              </Card.Header>
                                            </Card>
                                          </Accordion>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </Card.Body>
                            </Accordion.Collapse>
                            <Card.Header>
                              <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey={
                                  isIOAdmin && isExistingFinancial ? dIndex.toString() : '0'
                                }
                              >
                                <div className="pde-subdimension-name bodytext18-medium-midnight">
                                  {SDItem.subDimensionname}
                                </div>
                                <GrooveIconSvg
                                  customClassName="accordion-button"
                                  size="large"
                                  name="chevron-down"
                                  iconStyle="solid"
                                  iconColor="stat-neutral"
                                />
                              </Accordion.Toggle>
                            </Card.Header>
                          </Card>
                        </Accordion>
                      );
                    })}
                  </div>
                );
              })}

              {/*BUTTONS */}
              {this.props.projectDimensionsList.length === 0 ? (
                ''
              ) : (
                <>
                  <div id='track-scroll'>
                    <Row className="program-identified-buttons">
                      {this.props.RVDComponents.rvdParams || disableSuperAdmin ? null : (
                        <Row className="pde-btn sub-title-header">
                          <div className={!this.props.isSuperAdmin&&this.props.basicDetails.overallStatus=='Approved'?"reset-all-button-disabled":"reset-all-button"}>
                            <img className="resetAllBtn" src={resetAll} alt="reset-all-icon" />
                            <GrooveButton
                              id="primry-btn-1"
                              hasIcon={false}
                              type=""
                              colorClass=""
                              text="Reset All Values"
                              isDisabled={!this.props.isSuperAdmin&&this.props.basicDetails.overallStatus=='Approved'}
                              callbackFunction={() => this.props.handleShowResetAllModal()}
                              customClassName={!this.props.isSuperAdmin&&this.props.basicDetails.overallStatus=='Approved'?"resetAll-button-disabled":"resetAll-button"}
                            />
                          </div>
                        </Row>
                      )}
                      {this.props.RVDComponents.rvdParams || disableSuperAdmin ? null : (
                        <Row className="program-identified-buttons separator-right">
                          <div className={!this.props.isSuperAdmin&&this.props.basicDetails.overallStatus=='Approved'?"compute-all-button-disabled":"compute-all-button"}>
                            <img className="" src={computeAll} alt="compute-all-icon" />
                            <GrooveButton
                              id="primry-btn-1"
                              name="Solid Button Primary-Ops"
                              isDisabled={
                                
                                this.props.RVDComponents.sadButton ||
                                isOMIDContractIDExisting ||
                                (!this.props.isSuperAdmin && this.props.basicDetails.overallStatus === 'Approved') ||
                                this.props.isComputeDisabled 
                              }                              hasIcon={false}
                              type=""
                              customClassName="computeAll-button"
                              colorClass=""
                              size="auto"
                              text="Compute All"
                              callbackFunction={() => {
                                this.props.handleComputeAll(true);
                              }}
                            />
                          </div>
                        </Row>
                      )}
                      
                      <GrooveButton
                        id="primry-btn-1"
                        name="Solid Button Primary-Ops"
                        isDisabled={
                          (this.props.isSuperAdmin&&this.props.basicDetails.overallStatus=='Approved')||
                          (!this.props.isSuperAdmin&&this.props.basicDetails.overallStatus=='Approved')||
                          this.props.RVDComponents.sadButton ||
                          (!this.props.RVDComponents.submitButton &&
                            this.props.basicDetails.overallStatus.toLowerCase() === 'rejected') ||
                          isAgnosticTargetZero ||
                          this.props.isDisabled.sad ||
                          disableSADSFABtn
                        }
                        hasIcon={false}
                        type="outline"
                        colorClass="stat-alternate"
                        size="auto"
                        text="Save as Draft"
                        callbackFunction={this.props.onClickSaveAsDraft}
                      />                     
                      {this.props.RVDComponents.submitButton ? (
                        <>                      
                        {
                        this.props.isSuperAdmin&&this.props.basicDetails.overallStatus=='Approved'?
                        <GrooveButton
                            id="primry-btn-2"
                            name="Solid Button Primary-Ops"
                            hasIcon={false}
                            type="solid"
                            colorClass="stat-alternate"
                            size="auto"
                            text={'Update Project'}
                            callbackFunction={() => {
                              this.props.onUpdateProject();
                            }}
                            customClassName=""
                            isDisabled={
                              this.props.RVDComponents.sfaButton ||
                              isAgnosticTargetZero ||
                              this.props.isDisabled.sfa ||
                              this.props.isDisabled.reject ||
                              disableSADSFABtn
                            }
                          />
                         :
                          <GrooveButton
                            id="primry-btn-2"
                            name="Solid Button Primary-Ops"
                            hasIcon={false}
                            type="solid"
                            colorClass="stat-alternate"
                            size="auto"
                            text={
                              (this.props.isSuperAdmin &&
                                this.props.RVDComponents.approveRejectButton) ||
                                !IsArrayEmpty(assignedToList.filter((a) => a.label === this.props.basicDetails.dataSubmitter))
                                ||
                                (!this.props.isSuperAdmin && (this.props.currentUserRoleID === 12 ||
                                  this.props.currentUserRoleID === 15 ||
                                  (this.props.currentUserRoleID === 16 && this.props.isExistingPPCBA)))
                                ? 'Send for Approval'
                                : this.props.isRejected
                                  ? 'Submit Rejection'
                                  : 'Send for Approval'
                            }
                            callbackFunction={
                              this.props.basicDetails.overallStatus === 'In Progress' ||
                                !IsArrayEmpty(assignedToList.filter((a) => a.label === this.props.basicDetails.dataSubmitter)) ||
                                (this.props.isSuperAdmin &&
                                  this.props.RVDComponents.approveRejectButton) ||
                                ((!IsArrayEmpty(assignedToList.filter((a) => a.label === this.props.basicDetails.projectCreator
                                  || a.label === this.props.basicDetails.programCreator)) ||
                                  this.props.isSuperAdmin) &&
                                  !this.props.isRejected) ||
                                //FOR PPC USER SFA FOR CUMULATIVE VALUES
                                ((this.props.currentUserRoleID === 12 ||
                                  this.props.currentUserRoleID === 15 ||
                                  (this.props.currentUserRoleID === 16 &&
                                    this.props.isExistingPPCBA)) &&
                                  this.props.basicDetails.dataSubmitter === this.props.emailAddress &&
                                  this.props.basicDetails.overallStatus.toLowerCase() === 'approved')
                                ? this.props.isSuperAdmin &&
                                  this.props.basicDetails.overallStatus.toLowerCase() ===
                                  'awaiting approval'
                                  ? () => this.props.handleSendForApproval(false, true)
                                  : () =>
                                    this.props.handleSendForApproval(
                                      [
                                        'approved',
                                        'in progress',
                                        'rejected',
                                        '',
                                        'awaiting approval',
                                      ].indexOf(
                                        this.props.basicDetails.overallStatus.toLowerCase()
                                      ) > -1,
                                      false
                                    )
                                : this.props.currentUserRoleID === 12 ||
                                  this.props.currentUserRoleID === 15 ||
                                  (this.props.currentUserRoleID === 16 && this.props.isExistingPPCBA)
                                  ? () =>
                                    this.props.handleSendForApproval(
                                      [
                                        'approved',
                                        'in progress',
                                        'rejected',
                                        '',
                                        'awaiting approval',
                                      ].indexOf(this.props.basicDetails.overallStatus.toLowerCase()) >
                                      -1,
                                      false
                                    )
                                  : this.props.isRejected
                                    ? () => this.props.closeSubmitRejectionModal()
                                    : () => this.props.handleSendForApproval(false, true)
                            }
                            customClassName=""
                            isDisabled={
                              (!this.props.isSuperAdmin&&this.props.basicDetails.overallStatus=='Approved')||
                              this.props.RVDComponents.sfaButton ||
                              isAgnosticTargetZero ||
                              this.props.isDisabled.sfa ||
                              this.props.isDisabled.reject ||
                              disableSADSFABtn || this.props.inValidEditorFields.keyBusinessChallenges || this.props.inValidEditorFields.innovationEnablers || this.props.inValidEditorFields.businessOutcomes || this.props.invalidOtherClientApprovalRole
                            }
                          />
                          }
                        </>
                      ) : (
                        <GrooveButton
                          id="primry-btn-3"
                          name="Solid Button Primary-Ops"
                          isDisabled={
                            (IsArrayEmpty(assignedToList.filter((a) => a.label === this.props.emailAddress)) &&
                              !this.props.isSuperAdmin) ||
                            this.props.basicDetails.overallStatus.toLowerCase() === 'rejected' ||
                            this.props.isDisabled.reject ||
                            disableSADSFABtn
                          }
                          hasIcon={false}
                          type="solid"
                          colorClass="stat-alternate"
                          size="auto"
                          text={this.props.isRejected ? 'Submit Rejection' : 'Approve'}
                          callbackFunction={
                            this.props.isRejected
                              ? () => this.props.closeSubmitRejectionModal()
                              : () => this.props.handleSubmitForApproval()
                          }
                          customClassName=""
                        />
                      )}
                    </Row>
                  </div>
                  <div className={this.props.positionFixed ? !(this.props.RVDComponents.sadButton ||
                    (!this.props.RVDComponents.submitButton &&
                      this.props.basicDetails.overallStatus.toLowerCase() === 'rejected') ||
                    isAgnosticTargetZero ||
                    this.props.isDisabled.sad ||
                    disableSADSFABtn) || !(this.props.RVDComponents.sfaButton ||
                      isAgnosticTargetZero ||
                      this.props.isDisabled.sfa ||
                      this.props.isDisabled.reject ||
                      disableSADSFABtn) || !(this.props.RVDComponents.rvdParams || disableSuperAdmin) || (!(this.props.RVDComponents.rvdParams || disableSuperAdmin) && !this.props.RVDComponents.sadButton)
                    ? 'sticky-button' : 'sticky-button hide-sticky' : 'sticky-button hide-sticky'}>
                    <Row className="program-identified-buttons">
                      {this.props.RVDComponents.rvdParams || disableSuperAdmin ? null : (
                        <Row className="pde-btn sub-title-header">
                          <div className={!this.props.isSuperAdmin&&this.props.basicDetails.overallStatus=='Approved'?"reset-all-button-disabled":"reset-all-button"}>
                            <img className="resetAllBtn" src={resetAll} alt="reset-all-icon" />
                            <GrooveButton
                              id="primry-btn-1"
                              hasIcon={false}
                              type=""
                              colorClass=""
                              text="Reset All Values"
                              isDisabled={!this.props.isSuperAdmin&&this.props.basicDetails.overallStatus=='Approved'}
                              callbackFunction={() => this.props.handleShowResetAllModal()}
                              customClassName="resetAll-button"
                            />
                          </div>
                        </Row>
                      )}
                      {this.props.RVDComponents.rvdParams || disableSuperAdmin ? null : (
                        <Row className="program-identified-buttons separator-right">
                          <div className={!this.props.isSuperAdmin&&this.props.basicDetails.overallStatus=='Approved'?"compute-all-button-disabled":"compute-all-button"}>
                            <img className="" src={computeAll} alt="compute-all-icon" />
                            <GrooveButton
                              id="primry-btn-1"
                              name="Solid Button Primary-Ops"
                              isDisabled={
                                this.props.RVDComponents.sadButton ||
                                isOMIDContractIDExisting ||
                                (!this.props.isSuperAdmin && this.props.basicDetails.overallStatus === 'Approved') ||
                                this.props.isComputeDisabled // Disable button if any parameter value is null or empty
                              }                              hasIcon={false}
                              type=""
                              customClassName="computeAll-button"
                              colorClass=""
                              size="auto"
                              text="Compute All"
                              callbackFunction={() => {
                                this.props.handleComputeAll(true);
                              }}
                            />
                          </div>
                        </Row>
                      )}
                      <GrooveButton
                        id="primry-btn-1"
                        name="Solid Button Primary-Ops"
                        isDisabled={
                          (this.props.isSuperAdmin&&this.props.basicDetails.overallStatus=='Approved')||
                          (!this.props.isSuperAdmin&&this.props.basicDetails.overallStatus=='Approved')||
                          this.props.RVDComponents.sadButton ||
                          (!this.props.RVDComponents.submitButton &&
                            this.props.basicDetails.overallStatus.toLowerCase() === 'rejected') ||
                          isAgnosticTargetZero ||
                          this.props.isDisabled.sad ||
                          disableSADSFABtn
                        }
                        hasIcon={false}
                        type="outline"
                        colorClass="stat-alternate"
                        size="auto"
                        text="Save as Draft"
                        callbackFunction={this.props.onClickSaveAsDraft}
                        customClassName={(this.props.RVDComponents.sadButton ||
                          (!this.props.RVDComponents.submitButton &&
                            this.props.basicDetails.overallStatus.toLowerCase() === 'rejected') ||
                          isAgnosticTargetZero ||
                          this.props.isDisabled.sad ||
                          disableSADSFABtn) ? 'hide-sfa' : ''}
                      />
                      {this.props.RVDComponents.submitButton ? (
                        <>
                          <GrooveButton
                            id="primry-btn-2"
                            name="Solid Button Primary-Ops"
                            hasIcon={false}
                            type="solid"
                            colorClass="stat-alternate"
                            size="auto"
                            text={
                              (this.props.isSuperAdmin &&
                                this.props.RVDComponents.approveRejectButton) ||
                                !IsArrayEmpty(assignedToList.filter((a) => a.label === this.props.basicDetails.dataSubmitter))
                                ||
                                (!this.props.isSuperAdmin && (this.props.currentUserRoleID === 12 ||
                                  this.props.currentUserRoleID === 15 ||
                                  (this.props.currentUserRoleID === 16 && this.props.isExistingPPCBA)))
                                ? 'Send for Approval'
                                : this.props.isRejected
                                  ? 'Submit Rejection'
                                  : this.props.isSuperAdmin&&this.props.basicDetails.overallStatus=='Approved'?'Update Project':'Send for Approval'
                            }
                            callbackFunction={
                              this.props.isSuperAdmin&&this.props.basicDetails.overallStatus=='Approved'?(()=>this.props.onUpdateProject()) :(
                              this.props.basicDetails.overallStatus === 'In Progress' ||
                                !IsArrayEmpty(assignedToList.filter((a) => a.label === this.props.basicDetails.dataSubmitter)) ||
                                (this.props.isSuperAdmin &&
                                  this.props.RVDComponents.approveRejectButton) ||
                                ((!IsArrayEmpty(assignedToList.filter((a) => a.label === this.props.basicDetails.projectCreator
                                  || a.label === this.props.basicDetails.programCreator)) ||
                                  this.props.isSuperAdmin) &&
                                  !this.props.isRejected) ||
                                //FOR PPC USER SFA FOR CUMULATIVE VALUES
                                ((this.props.currentUserRoleID === 12 ||
                                  this.props.currentUserRoleID === 15 ||
                                  (this.props.currentUserRoleID === 16 &&
                                    this.props.isExistingPPCBA)) &&
                                  this.props.basicDetails.dataSubmitter === this.props.emailAddress &&
                                  this.props.basicDetails.overallStatus.toLowerCase() === 'approved')
                                ? this.props.isSuperAdmin &&
                                  this.props.basicDetails.overallStatus.toLowerCase() ===
                                  'awaiting approval'
                                  ? () => this.props.handleSendForApproval(false, true)
                                  : () =>
                                    this.props.handleSendForApproval(
                                      [
                                        'approved',
                                        'in progress',
                                        'rejected',
                                        '',
                                        'awaiting approval',
                                      ].indexOf(
                                        this.props.basicDetails.overallStatus.toLowerCase()
                                      ) > -1,
                                      false
                                    )
                                : this.props.currentUserRoleID === 12 ||
                                  this.props.currentUserRoleID === 15 ||
                                  (this.props.currentUserRoleID === 16 && this.props.isExistingPPCBA)
                                  ? () =>
                                    this.props.handleSendForApproval(
                                      [
                                        'approved',
                                        'in progress',
                                        'rejected',
                                        '',
                                        'awaiting approval',
                                      ].indexOf(this.props.basicDetails.overallStatus.toLowerCase()) >
                                      -1,
                                      false
                                    )
                                  : this.props.isRejected
                                    ? () => this.props.closeSubmitRejectionModal()
                                    : () => this.props.handleSendForApproval(false, true)
                              )
                                  }
                            customClassName={(this.props.RVDComponents.sfaButton ||
                              isAgnosticTargetZero ||
                              this.props.isDisabled.sfa ||
                              this.props.isDisabled.reject ||
                              disableSADSFABtn) ? 'hide-sfa' : ''}
                            isDisabled={
                              (!this.props.isSuperAdmin&&this.props.basicDetails.overallStatus=='Approved')||
                              this.props.RVDComponents.sfaButton ||
                              isAgnosticTargetZero ||
                              this.props.isDisabled.sfa ||
                              this.props.isDisabled.reject ||
                              disableSADSFABtn || this.props.inValidEditorFields.keyBusinessChallenges || this.props.inValidEditorFields.innovationEnablers || this.props.inValidEditorFields.businessOutcomes || this.props.invalidOtherClientApprovalRole
                            }
                          />
                        </>
                      ) : (
                        <GrooveButton
                          id="primry-btn-3"
                          name="Solid Button Primary-Ops"
                          isDisabled={
                            (IsArrayEmpty(assignedToList.filter((a) => a.label === this.props.emailAddress)) &&
                              !this.props.isSuperAdmin) ||
                            this.props.basicDetails.overallStatus.toLowerCase() === 'rejected' ||
                            this.props.isDisabled.reject ||
                            disableSADSFABtn
                          }
                          hasIcon={false}
                          type="solid"
                          colorClass="stat-alternate"
                          size="auto"
                          text={this.props.isRejected ? 'Submit Rejection' : 'Approve'}
                          callbackFunction={
                            this.props.isRejected
                              ? () => this.props.closeSubmitRejectionModal()
                              : () => this.props.handleSubmitForApproval()
                          }
                          customClassName={(IsArrayEmpty(assignedToList.filter((a) => a.label === this.props.emailAddress)) &&
                            !this.props.isSuperAdmin) ||
                            this.props.basicDetails.overallStatus.toLowerCase() === 'rejected' ||
                            this.props.isDisabled.reject ||
                            disableSADSFABtn ? 'hide-sfa' : ''}
                        />
                      )}
                    </Row>
                  </div>
                </>
              )}
            </div>
          </div>
          {/* FILE HISTORY */}
          <CVMModal
            title={'File History'}
            show={this.props.showFileHistory}
            onCloseModal={() => this.props.handleShowFileHistory(false)}
            customclass={'select-project-modal file-history-modal'}
            content={
              <ClientStoryAcknowledgementFileHistory
                type={this.props.type}
                fileHistoryData={this.props.clientAcknowledgementFileHistory}
              />
            }
          />

          {/* MODALS */}
          <CustomBOIModal
            isShow={this.props.showCustomBOI}
            onCustomBOIModalCancelClose={() => this.props.onClickCustomBOI(0, false)}
            subDimensionName={this.props.subDimensionName}
            dimensionName={this.props.dimensionName}
            boi={this.props.boi}
          />

          {/* <Attachment /> */}
          {this.props.showCommentary && (
            <CommentaryModal
              show={this.props.showCommentary}
              commentBOIDetails={this.props.commentBOIDetails}
              onCloseModal={this.props.handleBOIComment}
              enterpriseID={this.props.enterpriseID}
              editedAnyFieldOnce={this.props.editedAnyFieldOnce}
              setEditedAnyFieldOnce={this.props.setEditedAnyFieldOnce}
              checkForInvalidEmailsOnLanding={this.props.checkForInvalidEmailsOnLanding}
              overallStatus={this.props.basicDetails.overallStatus}
            />
          )}

          <CVMModal
            title={'Update Business Advisors'}
            show={this.props.showAddEditBA}
            onCloseModal={this.props.handleAddEditBA}
            customclass={'select-project-modal rvd-new-project-data-entry-modal'}
            content={
              <AddEditBusinessAdvisor
                businessAdvisor={this.props.businessAdvisor}
                businessAdvisorList={this.props.businessAdvisorList}
                handleAddEditBA={this.props.handleAddEditBA}
              />
            }
          />

          {this.props.showCommentaryHistory && (
            <CommentaryHistoryModal
              show={this.props.showCommentaryHistory}
              commentBOIDetails={this.props.commentBOIDetails}
              boiList={this.props.boiList}
              onCloseModal={this.props.handleBOICommentHistory}
            />
          )}

          <CVMModal
            title={''}
            show={this.props.showProjectDataEntry}
            onCloseModal={this.props.onCloseProjectDataEntry}
            customclass={'select-project-modal'}
            content={
              <CVMWarningModal
                handleCancel={this.props.onCloseProjectDataEntry}
                handleYes={this.props.closeProject}
                body={
                  <div>
                    <h5>Are you sure you want to close Project Data Entry?</h5>

                    <p>Please note that this would not save your changes. </p>
                  </div>
                }
              />
            }
          />
          <CVMModal
            title={''}
            show={this.props.showRedirectModal}
            onCloseModal={this.props.setRedirectModal}
            customclass={'select-project-modal'}
            content={
              <CVMWarningModal
                handleCancel={this.props.setRedirectModal}
                handleYes={this.props.redirectToPPC}
                body={
                  <div>
                    <h5>Are you sure you want to navigate to Project Configuration?</h5>

                    <p>Please note that this will discard your unsaved changes.</p>
                  </div>
                }
              />
            }
          />
          <CVMModal
            title={''}
            customclass={'notifyModal-dialog'}
            show={this.props.showConfirmApprovalModal}
            onCloseModal={() => this.props.onClickConfirmBoi(false)}
            content={
              <CVMWarningModal
                body={"Are you sure you would like to approve BOI's?"}
                handleCancel={() => this.props.onClickConfirmBoi(false)}
                handleYes={() => {
                  isFirstSubmission &&
                    (BAdvisors.length > 0 && !isBANotApp) &&
                    CXIOOAL
                    ? this.props.onClickYesNoBA(true)
                    : this.props.handleSendForApproval(false, false);
                }}
              />
            }
          />
          <CVMModal
            title={''}
            show={this.props.showCPPointsModal}
            customclass={'notifyModal-dialog'}
            onCloseModal={() => this.props.onClickYesNoBA(false)}
            content={
              <CPPointsModal
                BAList={!isBANotApp && BAdvisors.length > 0 ? BAdvisors : []}
                handleYesNo={() => this.props.handleSendForApproval(false, false)}
                handleCPYesNo={this.props.handleCPYesNo}
              />
            }
          />
          <CVMModal
            title={'Send for Approval'}
            customclass={'notifyModal-dialog'}
            show={this.props.approvalRequestModal}
            onCloseModal={this.props.closeRequestModal}
            content={
              <CVMSingleApprovalRequestModal
                onSend={() => this.props.onCXSendForApproval()}
                selectedDropdownValue={this.props.receiverDetails.dropdownVal}
                isDropdownDisabled={
                  this.props.receiverDetails.role === 'CXLLead' || this.props.receiverDetails.role === 'OperationsAccountLead'
                    ? false :
                    this.props.isProjectSelected
                      ? true
                      : this.props.isReportProjectSelected
                        ? true
                        : false
                }
                handleClickDropdown={this.props.isProjectSelected ? null : ''}
                onChangeTextBox={this.props.handleSFACommentChange}
                onCloseModal={this.props.closeRequestModal}
                label={
                  this.props.receiverDetails.role === 'IO Approver'
                    ? 'Intelligent Operation Approver'
                    : this.props.receiverDetails.role === 'CXLLead'
                      ? 'CX Lead'
                      : this.props.receiverDetails.role === 'OperationsAccountLead'
                        ? 'Operations Account Lead'
                        : this.props.receiverDetails.role
                }
                clientList={this.props.receiverDetails.dropdownList}
                actionType={'Send'}
                type={'SFA'}
                disableSendBtn={
                  (this.props.receiverDetails.roleID === 4 || this.props.receiverDetails.roleID === 7) ? //cxl || oal
                    !this.props.isDisabled.send ?
                      IsArrayEmpty(this.props.receiverDetails.dropdownVal)
                      :
                      this.props.isDisabled.send
                    : this.props.isDisabled.send //io
                }
                role={this.props.receiverDetails.role}
                handleUpdateReceiverDetails={this.props.handleUpdateReceiverDetails}
              />
            }
          />
          <CVMModal
            title={'Submit Rejection'}
            customclass={'notifyModal-dialog'}
            show={this.props.showSubmitRejectionModal}
            onCloseModal={this.props.closeSubmitRejectionModal}
            content={
              <CVMSingleApprovalRequestModal
                label={'Data Submitter'}
                onCloseModal={this.props.closeSubmitRejectionModal}
                onChangeTextBox={this.props.handleSFACommentChange}
                clientList={this.props.stakeHolders}
                options={[
                  {
                    label: this.props.basicDetails.dataSubmitter,
                    text: this.props.basicDetails.dataSubmitter,
                    value: this.props.basicDetails.dataSubmitter,
                  },
                ]}
                actionType={'Send'}
                isDropdownDisabled={true}
                selectedDropdownValue={{
                  label: this.props.basicDetails.dataSubmitter,
                  text: this.props.basicDetails.dataSubmitter,
                  value: this.props.basicDetails.dataSubmitter,
                }}
                onSend={this.props.onClickSubmitRejection}
                type={'Submit Reject'}
                disableSendBtn={this.props.isDisabled.send}
                receiverRole={this.props.receiverDetails.role}
              />
            }
          />
          <CVMModal
            title={''}
            customclass={'notifyModal-dialog'}
            show={this.props.showResetAllModal}
            onCloseModal={() => this.props.handleShowResetAllModal()}
            content={
              <CVMWarningModal
                body={'Are you sure you want to reset all values?'}
                handleCancel={() => this.props.handleShowResetAllModal()}
                handleYes={() => this.props.handleConfirmResetAll()}
              />
            }
          />

          {this.props.rejectionModal.show && (
            <CVMReportValueRejectionModal
              saveReject={this.props.saveReject}
              updateReason={this.props.updateReason}
              rejectionModal={this.props.rejectionModal}
              closeRejectionModal={this.props.closeRejectionModal}
              submitReject={this.props.submitReject}
              tempReason={this.props.tempReason}
            />
          )}
          {this.props.showcomments && (
            <CVMReportValueViewComments
              show={this.props.showcomments}
              closeCommentsSection={this.props.closeCommentsSection}
            />
          )}
          <CVMModal
            title={'Submit Project Data Entries'}
            customclass={'notifyModal-dialog submit-modal'}
            show={this.props.showSubmitModal}
            onCloseModal={this.props.closeSubmitModal}
            content={
              <CVMSingleApprovalRequestModal
                onCloseModal={this.props.closeSubmitModal}
                onSend={this.props.handleSendForFinalApproval}
                onChangeTextBox={this.props.handleComment}
                actionType={'Confirm'}
                type={'Submit Final'}
                disableSendBtn={this.props.isDisabled.send}
              />
            }
          />
          <CVMModal
            title={''}
            show={this.props.showSubmitDataRecord}
            onCloseModal={this.props.onCloseSubmitDataRecord}
            customclass={'select-project-modal'}
            content={
              <CVMWarningModal
                body={
                  <div>
                    <p>
                      Are you sure you want to submit the project data entry?
                    </p>
                  </div>
                }
                handleCancel={() => this.props.onCloseSubmitDataRecord()}
                handleYes={() => this.props.handleConfirmSubmitRecord()}
              />
            }
          />
          <CVMModal
            title={''}
            show={this.props.showCumulativeModal}
            onCloseModal={this.props.setCumulativeModal}
            customclass={'select-project-modal'}
            content={
              <CVMAlertModal
                handleOK={this.props.setCumulativeModal}
                body={
                  <div>
                    <h5>
                      You are expected to enter cumulative value (since inception till date) for all
                      BOIs.
                    </h5>

                    <p>
                      For the highlighted BOIs, the cumulative value does not seem to be
                      appropriate.{' '}
                    </p>
                    <p>
                      Click OK and kindly review the value and re-compute before sending the data
                      for approval.{' '}
                    </p>
                  </div>
                }
              />
            }
          />

        </>
      );
    }
  }

  class ConfigureProgramContainer extends Component {
    constructor() {
      super();
      this.state = {
        isComputeDisabled:true,
        isCPYes: false,
        reportingYearOptions: [],
        approvalRequestModal: false,
        showRejectionModal: false,
        showcomments: false,
        showSubmitRejectionModal: false,
        showSubmitModal: false,
        showProjectDataEntry: false,
        showRedirectModal: false,
        Files: [],
        comment: '',
        isRejected: false,
        radioButtonIdList: [],
        stakeHolders: [],
        fileIsValid: null,
        errorUpload: '',
        rejectionModal: {
          show: false,
          dimensionIndex: 0,
          subDimensionIndex: 0,
          boiIndex: 0,
        },
        tempReason: '',
        currentBOIStatus: {
          status: '',
          dimensionIndex: 0,
          subDimensionIndex: 0,
          boiIndex: 0,
          ID: 0,
        },
        activeExpand: false,
        activeCollapse: false,
        rejectReasonClicked: false,
        submitReject: false,
        accordionCount: 0,
        tempBOIs: [],
        dimensionName: '',
        subDimensionName: '',
        boi: {
          ClientName: '',
          offeringName: '',
          boiName: '',
          uom: '',
          description: '',
          LeadingIndicator: '',
          comment: '',
          MetricCalculation: '',
          customParameterList: [],
          AggregationLogic: '',
          SubOffering: '',
          AggregationLogicId: 0,
          MetricReason: '',
          AggregateReason: '',
        },
        showConfirmApprovalModal: false,
        showCPPointsModal: false,
        showCommentary: false,
        showCommentaryHistory: false,
        commentBOIDetails: [],
        boiList: [],
        showResetAllModal: false,
        isComment: false,
        showSubmitDataRecord: false,
        showRepPerHighlighted: false,
        previouslyAlerted: false,
        showClientStory: false,
        clientModalType: '',
        hasNoCSAttachment: false,
        hasOldClientStoryFileError: false,
        hasNoCAAttachment: false,
        showNotifMessage: false,
        showNotifMessageArr: {
          financial: false,
          experience: false,
          sustainability: false,
          talent: false,
          inclusiondiversity: false,
          custom: false,
        },
        clientApproverRoleList: [],
        submitStakeHolders: [],
        isDisabled: {
          send: false,
          sfa: false,
          reject: false,
          sad: false,
        },
        showFileHistory: false,
        clientApprover:
        {
          clientApproverRole: "",
          clientApproverRoleEmpty: false,
          otherSpecify: "",
          otherSpecifyEmpty: false
        },
        isDisabledReminderButton: true,
        positionFixed: false,
        isValidMappedFields: {
          projCreator: true,
          dataSubmitter: true,
          ioApprover: true,
          cxl: true,
          oal: true,
          ba: true,
          mappedFieldErrMsgs: []
        },
        inValidEditorFields: {
          keyBusinessChallenges: false,
          innovationEnablers: false,
          businessOutcomes: false,
        },
        invalidOtherClientApprovalRole: false,
        anyInvalidEmails: false,
        editedAnyFieldOnce: false,
      };
      this.tempBasicDetails = {};
      this.hadCompute = false;
    }

    setEditedAnyFieldOnce = (value) => {
      this.setState({
        editedAnyFieldOnce: value
      })
    }

    setIsComputeDisabled = (value) => {
      this.setState({
        isComputeDisabled: value
      })
    }

    changeInvalidEditorFields = (type, value) => {
      this.setState(prevState => ({
        inValidEditorFields : {
          ...prevState.inValidEditorFields,
          [type]: value,
        }
      }))
    }

    listenToScroll = () => {
      let trackScroll = document.getElementById('track-scroll');
      let var1 = trackScroll ? document.getElementById('root').clientHeight - trackScroll.offsetTop : 0;
      let var2 =
        document.getElementById('root').clientHeight -
        document.documentElement.scrollTop -
        window.innerHeight;

      if (var2 > var1) {
        this.setState({
          positionFixed: true,
        });
      } else {
        this.setState({
          positionFixed: false,
        });
      }
    };

    checkForInvalidEmailsOnLanding = async () => {
      let isValid = true;
      let cxLeadList = this.props.ConfigureProgramData.basicDetails.cxLead.length ? this.props.ConfigureProgramData.basicDetails.cxLead.split(',') : [];
      let oaLeadList = this.props.ConfigureProgramData.basicDetails.oaLead.length ? this.props.ConfigureProgramData.basicDetails.oaLead.split(';') : [];
      let baList = this.props.ConfigureProgramData.businessAdvisorList.length ? 
        this.props.ConfigureProgramData.businessAdvisorList.map(ba => ba.label).filter(item => item !== 'Not Applicable') : 
        [];
      let ioApproverList = this.props.ConfigureProgramData.basicDetails.ioApprover !== null ? this.props.ConfigureProgramData.basicDetails.ioApprover.split(',') : [];
      let projectCreatorList = this.props.ConfigureProgramData.basicDetails.projectCreator !== null ? this.props.ConfigureProgramData.basicDetails.projectCreator.split(',') : [];

      const allValues = [...cxLeadList, ...oaLeadList, ...ioApproverList, ...baList, ...projectCreatorList];
      const data = await IsValidAccentureEmail(allValues.filter(item => item !== 'System generated' && item !== 'System Generated'));

      for (let i = 0; i < data.length; i++) {
        if (!data[i].IsValid) {
          isValid = false;
          break;
        }
      }

      this.setState({
        anyInvalidEmails: !isValid,
      })
    }

    componentDidMount = async () => {
      window.scrollTo(0, 0)
      this.listenToScroll();
      this.props.setEmailError({
        show: false,
        message: ""
      })
      if(this.props.ConfigureProgramData.basicDetails.oldClientStoryFileExists){
          this.setState({
            hasOldClientStoryFileError: true
          })
      }
      //DataEvent("rvdPDE", this.props.SharedData.selectedClient.text);
      if (!IsValid(this.props.clientID)) this.closeProject();
      if (!IsValidStr(this.props.ConfigureProgramData.basicDetails.assignedTo)) {
        this.setState({
          isDisabledReminderButton: true
        })
      }
      this.loadReportingyear();
      this.setRadioBtnIdList();
      this.setStakeHolders(false, this.props.UserInformation.currentUserRoleID);
      await this.setClientApproverRoleList();

      let isSuperAdmin = this.props.UserInformation.isSuperAdmin;
      let currentUserRoleID = this.props.UserInformation.currentUserRoleID;
      let isIOAdmin = //super admin inclused io approver
        (isSuperAdmin && this.props.ConfigureProgramData.basicDetails.assignedToRoleID === 16) ||
        (currentUserRoleID === 16 &&
          this.props.ConfigureProgramData.basicDetails.assignedToRoleID === currentUserRoleID);

      let isExistingFinancial =
        this.props.ConfigureProgramData.projectDimensionsList.filter(
          (dim) => dim.dimensionName.toLowerCase() === 'financial'
        ).length > 0;
      let projectDetails = this.props.ConfigureProgramData.mainSelectedProject.projectDetails;
      if (IsValidStr(projectDetails.clientApproverRole) && this.state.clientApproverRoleList.length > 0) {
        this.handleSetClientApproverRole()
      }

      this.setState({
        activeExpand: isIOAdmin ? false : true,
        activeCollapse: isIOAdmin ? (isExistingFinancial ? false : true) : false,
        accordionCount: this.props.ConfigureProgramData.basicDetails.noOfSubdimensions,
      });

      this.setDefaultRadioValue();
      this.showDisclaimerMessage();
      this.validateMappedFields();
      this.getDisclaimerDetails();

      const mainSel = this.props.ConfigureProgramData.mainSelectedProject;

      LogUserSiteAction({
        module: PDE,
        ShouldLogPPCID: true,
        ClientProgramID: TryGetNodeAlt(mainSel, null, 'programID'),
        ClientProjectID: TryGetNodeAlt(mainSel.projectDetails, null, 'projectID'),
        projStatus:
          TryGetNodeAlt(mainSel.projectDetails, null, 'status') === null ? null : ACC_PROJ,
      });
      const isCXLStage = this.props.ConfigureProgramData.basicDetails.assignedToRole.trim().toLowerCase() === "cx lead"
      const isIOAStage = this.props.ConfigureProgramData.basicDetails.assignedToRole.trim().toLowerCase() === "intelligent ops approver"
      const { selectedClient } = this.props.SharedData;
      if (this.props.ConfigureProgramData.basicDetails.overallStatus === 'Awaiting Approval' && !isExistingFinancial) {
        const notifTypeID = isCXLStage ? 5 : isIOAStage ? 7 : 6;
        this.setState({
          isDisabledReminderButton: true
        });
        let counterReminder = await this.props.getReminderCount({ ...mainSel, ...selectedClient, notifTypeID }, "PDE")
        if (counterReminder >= 2) {
          this.setState({
            isDisabledReminderButton: true
          });
        } else {
          this.setState({
            isDisabledReminderButton: false
          });
        }
      }

      document
        .getElementsByClassName('collapse')[0]
        .parentElement.childNodes[1].children[0].click();

      if (this.props.ConfigureProgramData.basicDetails.overallStatus !== 'Approved') {
        await this.checkForInvalidEmailsOnLanding();
      }
    };
    componentDidUpdate(prevProps) {
      window.addEventListener('scroll', this.listenToScroll);
      if (this.props.clientID !== prevProps.clientID) {
        this.props.history.push('/DataIngestion'); //back to landing page-Bug 184531
      }
      if (
        JSON.stringify(this.props.ConfigureProgramData.basicDetails) !==
        JSON.stringify(this.tempBasicDetails)
      ) {
        this.setResourceState();
        this.loadReportingyear();
      }
    }
    componentWillUnmount() {
      this.props.resetRefresh();
    }
    setRadioBtnIdList = () => {
      try {
        const selectedProject = this.props.ConfigureProgramData.projectDimensionsList;
        let obj = [];
        let bois = [];
        let isRejected = false;

        selectedProject.map((a) => {
          a.subDimensionList.map((b) => {
            b.boiList.map((c, i) => {
              obj.push({
                value: c.boiStatus === '' || c.boiStatus === 'Approve' ? true : false,
                id: c.boiID,
              });
              bois.push({
                value: c.boiStatus === '' ? 'Approve' : c.boiStatus,
                id: c.boiID,
              });
              if (
                !isRejected &&
                c.boiStatus === 'Rejected' &&
                this.props.UserInformation.currentUserRoleID !== 10
              ) {
                isRejected = true;
              }
            });
          });
        });

        this.setState({
          radioButtonIdList: obj,
          tempBOIs: bois,
          isRejected: isRejected,
          rejectReasonClicked: this.props.ConfigureProgramData.isDraft,
        });
      } catch (err) {
        console.log(err);
      }
    };

    setStakeHolders = async (isReject, uRoleID) => {
      try {
        const basicDetails = this.props.ConfigureProgramData.basicDetails;
        let subMenuId = GetSubMenuId("Project Data Entry")
        const data = await HttpGet(
          `EmailService/GetProjectEmailRecipients?clientProjectId=${basicDetails.projectID}&ProjectDeliveryCenterID=${basicDetails.projectDeliveryCenterID}&SubMenuID=${subMenuId}`
        );

        const rejectRecepients = this.props.ConfigureProgramData.receiverDetails.dropdownVal;
        const projectLeads = this.props.ConfigureProgramData.basicDetails.projectLead;
        let isFinalStage = !this.props.AccessValidationData.RVDComponents.submitButton;
        let userRoleID = this.props.UserInformation.isSuperAdmin
          ? this.props.ConfigureProgramData.mainSelectedProject.projectDetails.assignedToRoleId
          : uRoleID;
        let isExistingOAL = await this.props.isProfileExisting([4]);
        let isExistingCXL = await this.props.isProfileExisting([7]);

        let obj = [];
        let submitStakeHolders = [];

        if (isReject) {
          //Rejected
          if (userRoleID === 4 || isFinalStage || (userRoleID === 16 && isExistingOAL)) {
            //Ops account
            obj.push({
              value: obj.length,
              label: projectLeads,
              text: projectLeads,
              isChecked: true,
            });

            obj.push(rejectRecepients);
          } else if (userRoleID === 8 || userRoleID === 16) {
            //IO Approver
            obj.push(rejectRecepients);
          } else if (userRoleID === 7 || (userRoleID === 16 && isExistingCXL)) {
            //CX lead
            obj.push(rejectRecepients);
          }
        } else {
          //Approve
          if (userRoleID === 4 || isFinalStage || (userRoleID === 16 && isExistingOAL)) {
            //Ops account
            const isSuccess = await this.props.getSRDetails();
            if (isSuccess) {
              obj.push(this.props.ConfigureProgramData.receiverDetails.dropdownVal);
            }
            if (data) {
              //Stake Holders for submit modal
              //--Converts data (obj) to an array of obj
              let stakeHoldersData = Object.entries(data).map((entry) => ({
                role: entry[0],
                value: entry[1],
              }));
              //--Filter only needed stakeholders
              stakeHoldersData.map((obj) => {
                if (
                  HasValue(obj.role) && HasValue(obj.value) && (((obj.role.toLowerCase() === 'projectcreator' &&
                    obj.value !== 'System Generated') ||
                    obj.value !== 'System Approved' ||
                    obj.role.toLowerCase() === 'cxlead' ||
                    obj.role.toLowerCase() === 'operationaccountlead' ||
                    obj.role.toLowerCase() === 'datasubmitter') &&
                    !submitStakeHolders.some(
                      (sh) => sh.label === obj.value
                    ))
                ) {
                  if (obj.role.toLowerCase() === 'cxlead' || obj.role.toLowerCase() === 'operationaccountlead') {
                    obj.value.split(",").map((a) => {
                      submitStakeHolders.push({
                        value: submitStakeHolders.length,
                        label: a,
                        text: a,
                        isChecked: true,
                      });
                    })
                  } else {
                    submitStakeHolders.push({
                      value: submitStakeHolders.length,
                      label: obj.value,
                      text: obj.value,
                      isChecked: true,
                    });
                  }
                }
              });

              //Stake holders for overall
              data.ProjectStakeholders.map((stakeholder) => {
                if (
                  stakeholder !== null &&
                  stakeholder !== 'System Generated' &&
                  !obj.some((sh) => sh.label === stakeholder)
                ) {
                  obj.push({
                    value: obj.length,
                    label: stakeholder,
                    text: stakeholder,
                    isChecked: true,
                  });
                }
              });
            }
          }
        }
        this.setState({
          stakeHolders: obj,
          submitStakeHolders: submitStakeHolders,
        });
      } catch (err) {
        console.log(err);
      }
    };

    setResourceState = async () => {
      let tState = {};

      if (
        IsValid(this.props.clientID) &&
        !IsObjEmpty(this.props.ConfigureProgramData.basicDetails)
      ) {
        let userRole = this.props.UserInformation.currentUserRoleID;
        let status = this.props.ConfigureProgramData.basicDetails.overallStatus;
        let isDraft = this.props.ConfigureProgramData.isDraft;
        let isExisting784 = await this.props.isProfileExisting([7, 8, 4]);
        let isExistingPPCBA = await this.props.isProfileExisting([12, 15]);
        //cx lead; io approver; ops acct lead
        if (
          userRole === 7 ||
          userRole === 8 ||
          userRole === 4 ||
          (userRole === 16 && isExisting784)
        ) {
          tState = {
            isSelectProjectDisabled: status === 'Awaiting Approval' ? true : false,
            isCommentDisabled: status === 'Awaiting Approval' ? !isDraft : false,
            isSaveAsDraftDisabled: false,
          };
        } //  Program and Project Configurator || Business Advisor
        else if (userRole === 12 || userRole === 15 || (userRole === 16 && isExistingPPCBA)) {
          tState = {
            //In Progress/Approved
            isSelectProjectDisabled: status === 'Awaiting Approval' ? true : false,
            isCommentDisabled: status === 'Rejected' ? false : true,
            isSaveAsDraftDisabled: false,
          };
        } else {
          tState = IS_RESOURCE_DISABLED;
        }
      } else {
        tState = {
          isSelectProjectDisabled: !IsValid(this.props.clientID),
          isCommentDisabled: true,
          isSaveAsDraftDisabled: true,
        };
      }
      this.tempBasicDetails = this.props.ConfigureProgramData.basicDetails;
      this.props.setConfProgButtonState(tState);
    };
    loadReportingyear = () => {
      if (!IsValid(this.props.reportingYear)) return;
      const tempList = this.props.reportingYear.map((e) => {
        return {
          value: e.year,
          label: e.year,
          text: e.year,
        };
      });
      this.setState({ reportingYearOptions: tempList });
    };
    closeRequestModal = () => {
      this.setState({ approvalRequestModal: !this.state.approvalRequestModal });
    };

    closeRejectionModal = (indexes) => {
      try {
        let data = this.state.radioButtonIdList;
        //logic check if there is change in reject reason
        data
          .filter((x) => x.id === this.state.currentBOIStatus.ID)
          .map((y) => {
            let isRejected = this.state.currentBOIStatus.status === 'Rejected' ? false : true;
            return {
              ...(y.value = isRejected),
            };
          });

        this.props.saveRejectionReason(this.state.currentBOIStatus.status, '', indexes, true);
        this.setState(
          {
            radioButtonIdList: data,
            rejectionModal: {
              show: false,
              dimensionIndex: 0,
              subDimensionIndex: 0,
              boiIndex: 0,
            },
            currentBOIStatus: {
              status: '',
              dimensionIndex: 0,
              subDimensionIndex: 0,
              boiIndex: 0,
              ID: 0,
            },
            isRejected: data.filter((x) => !x.value).length >= 1,
            submitReject: false,
          },
          () => {
            this.setStakeHolders(
              this.state.isRejected,
              this.props.UserInformation.currentUserRoleID
            );
          }
        );

        document.body.style.overflowY = 'visible';
      } catch (e) {
        console.log(e);
      }
    };

    closeCommentsSection = () => {
      try {
        this.setState({
          ...this.state,
          showcomments: !this.state.showcomments,
        });
      } catch (e) {
        console.log(e);
      }
    };

    closeSubmitRejectionModal = async () => {
      try {
        if (!this.state.showSubmitRejectionModal) {
          this.setState(
            {
              isDisabled: { ...this.state.isDisabled, reject: true },
            },
            async () => {
              const basicDetails = this.props.ConfigureProgramData.basicDetails;

              let isValid = await this.validatePDEForm(false);
              const isValidReject = await this.props.validateRejectionComment();

              if (!isValid.result || isValidReject) {
                this.setState({
                  submitReject: isValidReject ? true : this.state.submitReject,
                  isDisabled: { ...this.state.isDisabled, reject: false },
                });
                return;
              } else {
                this.setState({ submitReject: !isValidReject ? false : this.state.submitReject });
              }

              //check Client Approver
              let validClientApprover = this.checkClientApproverRole()
              if (!validClientApprover) {
                window.scrollTo({ top: 130, left: 185, behavior: 'smooth' });
                this.setState({
                  isDisabled: { ...this.state.isDisabled, reject: false }
                })
                return
              }

              const isSuccess = await this.props.getSRDetails();
              if (isSuccess) {
                this.setStakeHolders(
                  this.state.isRejected,
                  this.props.UserInformation.currentUserRoleID
                );
                this.setState({
                  showSubmitRejectionModal: !this.state.showSubmitRejectionModal,
                });
              }
              this.setState({
                isDisabled: { ...this.state.isDisabled, reject: false },
              });
            }
          );
        } else {
          this.setState({
            showSubmitRejectionModal: !this.state.showSubmitRejectionModal,
          });

          this.props.setEmailError({
            show: false,
            message: "",
          })
        }
      } catch (e) {
        console.log(e);
      }
    };

    closeSubmitModal = () => {
      try {
        this.setState({
          ...this.state,
          showSubmitModal: !this.state.showSubmitModal,
          Files: [],
        });

        this.props.setEmailError({
          show: false,
          message: "",
        })
      } catch (e) {
        console.log(e);
      }
    };

    onClickConfirmBoi = async (e) => {
      this.setState({ showConfirmApprovalModal: e });
    };

    onClickYesNoBA = (e) => {
      this.setState({ showCPPointsModal: e }, () => {
        if (e === true) {
          this.setState({ showConfirmApprovalModal: false });
        }
      });
    };

    handleCPYesNo = (e) => {
      this.setState({ isCPYes: e });
    };

    onCXSendForApproval = async () => {
      let mainSelectedProject = this.props.ConfigureProgramData.mainSelectedProject;
      let receiverDetails = this.props.ConfigureProgramData.receiverDetails;
      const userEmail = this.props.UserInformation.emailAddress.trim().toLowerCase();
      const cxLeadEmail = mainSelectedProject.projectDetails.cxLead
        .trim()
        .toLowerCase();
      const ioApprover = IsValid(this.props.ConfigureProgramData.basicDetails.ioApprover)
        ? this.props.ConfigureProgramData.basicDetails.ioApprover.trim().toLowerCase()
        : '';
      const oaLead = IsValid(this.props.ConfigureProgramData.basicDetails.oaLead)
        ? this.props.ConfigureProgramData.basicDetails.oaLead.trim().toLowerCase()
        : '';
      const assignedToEmail =
        mainSelectedProject.projectDetails.assignedTo
          .trim()
          .toLowerCase();
      const isFirstSubmission =
        mainSelectedProject.projectDetails.isFirstSubmission === 1
          ? false
          : true;
      let assignedToList = mainSelectedProject.projectDetails.assignedToList;
      let cxLeads = !IsArray(receiverDetails.dropdownVal) ? [receiverDetails.dropdownVal] : receiverDetails.dropdownVal;

      const isAssignedToCX = !IsArrayEmpty(assignedToList.filter((a) => cxLeads.find((b) => a.label === b.label))) ?
        cxLeadEmail : false;
      const isSuperAdmin = this.props.UserInformation.isSuperAdmin;
      let isExistingCXL = await this.props.isProfileExisting([7]);
      const isCXLead =
        this.props.ConfigureProgramData.mainSelectedProject.projectDetails.assignedToRoleId === 7 ||
          (this.props.ConfigureProgramData.mainSelectedProject.projectDetails.assignedToRoleId ===
            16 &&
            isExistingCXL)
          ? true
          : false;

      const clientApproverRole = this.state.clientApprover
      if (
        ((isAssignedToCX === userEmail || isSuperAdmin) &&
          isCXLead &&
          isFirstSubmission &&
          isAssignedToCX !== ioApprover) || //for normal flow
        (isFirstSubmission &&
          ioApprover === assignedToEmail &&
          (cxLeadEmail === ioApprover || oaLead === ioApprover))
      ) {
        //skip 1 approver when same user is used
        this.setState(
          {
            isDisabled: { ...this.state.isDisabled, send: true },
          },
          async () => {
            await this.props.onCPPointsYesNo(this.state.isCPYes);
            await this.props.onClickSelectProjectSFA(clientApproverRole);
            this.setState({
              isDisabled: { ...this.state.isDisabled, send: false },
            });
          }
        );
      } else {
        this.setState(
          {
            isDisabled: { ...this.state.isDisabled, send: true },
          },
          async () => {
            await this.props.onClickSelectProjectSFA(clientApproverRole);
            this.setState({
              isDisabled: { ...this.state.isDisabled, send: false },
            });
          }
        );
      }

      let eventCode = "";
      switch (receiverDetails.role) {
        case "IO Approver":
          eventCode = "rvdSFACXL";
          break;
        case "CXLLead":
          eventCode = "rvdSFAIOA";
          break;
        case "OperationsAccountLead":
          eventCode = "rvdSFAOAL";
          break;
        default:
          break;
      }
      //DataEvent(eventCode, this.props.SharedData.selectedClient.text);
    };
    onUpdateProject=async()=>{
      const clientApproverRole = this.state.clientApprover;
      
     const basicDetails = this.props.ConfigureProgramData.basicDetails;
              const oldClientStoryFileExists = basicDetails.oldClientStoryFileExists
              const {
                businessOutcomes,
                innovationEnablers,
                keyBusinessChallenges,
              } = this.props.ConfigureProgramData.clientStory;
              const currentUserRoleID = this.props.UserInformation.currentUserRoleID;
              const computeBOI = this.hadCompute;
              // let isErrorBA = this.state.isErrorBA;
              let shouldCompute = false;
              //let isSuccess = false;

              if (
                !computeBOI &&
                 // ovStatus === 'approved' &&
                (currentUserRoleID !== 4 ||
                  currentUserRoleID !== 7 ||
                  currentUserRoleID !== 8 ||
                  currentUserRoleID !== 16)
              ) {
                shouldCompute = true;
              }

              let isValid = await this.validatePDEForm(shouldCompute);
              
              if( businessOutcomes.length &&
                innovationEnablers.length &&
                keyBusinessChallenges.length){
                  this.setState({
                    hasNoCSAttachment: false,
                  });
              } else {
                  isValid.result = false;
                  if(oldClientStoryFileExists){
                    this.setState({ hasOldClientStoryFileError: true, hasNoCSAttachment: false },
                      () => {
                        window.scrollTo({ top: 165, left: 165, behavior: 'smooth' });
                        return;
                      }
                    );
                  } else {
                    this.setState({ hasNoCSAttachment: true, hasOldClientStoryFileError: false },
                    () => {
                      window.scrollTo({ top: 165, left: 165, behavior: 'smooth' });
                      return;
                    }
                  );
                  }
              }
             

              if (
                this.props.ConfigureProgramData.clientAlignmentFiles.file.length === 0 &&
                (!HasProperty(
                  this.props.ConfigureProgramData.mainSelectedProject.projectDetails
                    .recentlyUploadedClientAlignment[0],
                  'FileName'
                ) ||
                  this.props.ConfigureProgramData.removeClientAlignmentFileId.length > 0)
              ) {
                isValid.result = false;
                this.setState(
                  {
                    hasNoCAAttachment: true,
                  },
                  () => {
                    window.scrollTo({ top: 165, left: 165, behavior: 'smooth' });
                    return;
                  }
                );
              } else {
                this.setState({
                  hasNoCAAttachment: false,
                });
              }
              let hasValidCumulativeRV = await this.checkCumulative();
              let section = document.querySelector('#invalid-cumulative');

              if (!hasValidCumulativeRV && IsValid(section)) {
                section.scrollIntoView({ behavior: 'smooth', block: 'center' });
                this.setState({
                  isDisabled: { ...this.state.isDisabled, sfa: false },
                });
                return;
              }

              //check Client Approver
              let validClientApprover = this.checkClientApproverRole()
              if (!validClientApprover) {
                window.scrollTo({ top: 130, left: 185, behavior: 'smooth' });
                this.setState({
                  isDisabled: { ...this.state.isDisabled, sfa: false }
                })
                return
              }
              if(isValid.result){
                this.props.onClickSelectProjectSFA(clientApproverRole);
              }

    }
    handleSendForApproval = (newData, approver) => {
      try {
        if (!this.state.isDisabled.sfa) {
          this.setState(
            {
              isDisabled: { ...this.state.isDisabled, sfa: true },
            },
            async () => {
              const basicDetails = this.props.ConfigureProgramData.basicDetails;
              const oldClientStoryFileExists = basicDetails.oldClientStoryFileExists
              const {
                businessOutcomes,
                innovationEnablers,
                keyBusinessChallenges,
                isExistingProject
              } = this.props.ConfigureProgramData.clientStory;
              const ovStatus = basicDetails.overallStatus.toLowerCase();
              const currentUserRoleID = this.props.UserInformation.currentUserRoleID;
              const computeBOI = this.hadCompute;
              let shouldCompute = false;
              let isSuccess = false;

              if (
                !computeBOI &&
                // ovStatus === 'approved' &&
                (currentUserRoleID !== 4 ||
                  currentUserRoleID !== 7 ||
                  currentUserRoleID !== 8 ||
                  currentUserRoleID !== 16)
              ) {
                shouldCompute = true;
              }

              let isValid = await this.validatePDEForm(shouldCompute);
              
              if( businessOutcomes.length &&
                innovationEnablers.length &&
                keyBusinessChallenges.length){
                  this.setState({
                    hasNoCSAttachment: false,
                  });
              } else {
                  isValid.result = false;
                  if(oldClientStoryFileExists){
                    this.setState({ hasOldClientStoryFileError: true, hasNoCSAttachment: false },
                      () => {
                        window.scrollTo({ top: 165, left: 165, behavior: 'smooth' });
                        return;
                      }
                    );
                  } else {
                    this.setState({ hasNoCSAttachment: true, hasOldClientStoryFileError: false },
                    () => {
                      window.scrollTo({ top: 165, left: 165, behavior: 'smooth' });
                      return;
                    }
                  );
                  }
              }
              

              if (
                this.props.ConfigureProgramData.clientAlignmentFiles.file.length === 0 &&
                (!HasProperty(
                  this.props.ConfigureProgramData.mainSelectedProject.projectDetails
                    .recentlyUploadedClientAlignment[0],
                  'FileName'
                ) ||
                  this.props.ConfigureProgramData.removeClientAlignmentFileId.length > 0)
              ) {
                isValid.result = false;
                this.setState(
                  {
                    hasNoCAAttachment: true,
                  },
                  () => {
                    window.scrollTo({ top: 165, left: 165, behavior: 'smooth' });
                    return;
                  }
                );
              } else {
                this.setState({
                  hasNoCAAttachment: false,
                });
              }
              let hasValidCumulativeRV = await this.checkCumulative();
              let section = document.querySelector('#invalid-cumulative');

              if (!hasValidCumulativeRV && IsValid(section)) {
                section.scrollIntoView({ behavior: 'smooth', block: 'center' });
                this.setState({
                  isDisabled: { ...this.state.isDisabled, sfa: false },
                });
                return;
              }

              //check Client Approver
              let validClientApprover = this.checkClientApproverRole()
              if (!validClientApprover) {
                window.scrollTo({ top: 130, left: 185, behavior: 'smooth' });
                this.setState({
                  isDisabled: { ...this.state.isDisabled, sfa: false }
                })
                return
              }


              if (isValid.result && isValid.error === '') {
                isSuccess = await this.props.getSFADetails("sfa");

                if (isSuccess) {
                  if (newData && !approver) {
                    this.setState({ showSubmitDataRecord: true });
                  } else if (!newData && approver) {
                    this.setState({ showConfirmApprovalModal: true });
                  } else if (!newData && !approver) {
                    this.setState({
                      showConfirmApprovalModal: false,
                      showCPPointsModal: false,
                      showSubmitDataRecord: false,
                    });
                    this.closeRequestModal();
                  }
                }
              }
              this.setState({
                isDisabled: { ...this.state.isDisabled, sfa: false },
              });
            }
          );
        }
      } catch (e) {
        console.log(e);
      }
    };

    checkCumulative = () => {
      const {
        rvdSelectedTabs,
        projectDimensionsList } = this.props.ConfigureProgramData;
      let isValid = false
      const financialId = projectDimensionsList.findIndex(dItem => dItem.dimensionID === 1);

      if (financialId > -1) {
        projectDimensionsList[financialId].subDimensionList.forEach((sdItem) => {
          const boiListUSD = sdItem.boiList.filter((boiItem) => boiItem.boiUOM.toLowerCase() === 'usd');

          //For already approved once PDE in SAD - Bug 211600
          boiListUSD.forEach((b) => { //boi
            if (HasValue(b.boiApprovedRealizedValue)) { //check if already approved once
              b.parameterList.forEach((p) => { //parameter
                if (!HasProperty(p, 'hasChange')) { //check if there are changes in param 
                  /* if (
                    HasValue(b.boiApprovedRealizedValue) &&
                    b.leadingIndicator.toLowerCase() === 'maximum' &&
                    b.boiApprovedRealizedValue > b.boiRealizedValue
                  ) {
                    b['realizedValInvalid'] = true;
                  } else */
                  /* if (
                    HasValue(b.boiApprovedRealizedValue) &&
                    b.leadingIndicator.toLowerCase() === 'minimum' &&
                    b.boiApprovedRealizedValue < b.boiRealizedValue
                  ) {
                    b['realizedValInvalid'] = true;
                  } */
                }
              })
            }
          })
          let hasInvalidRealized = boiListUSD.some((boiItem) => boiItem.realizedValInvalid === true);

          if (hasInvalidRealized) {
            rvdSelectedTabs[sdItem.subDimensionname.toLowerCase()] = 0;
            isValid = false;
          } else {
            isValid = true;
          }
        });
      }

      this.props.setRVDSelectedTabs(rvdSelectedTabs);
      return isValid;
    };

    onClickSaveAsDraft = async () => {
      try {
        this.setState(
          {
            isDisabled: { ...this.state.isDisabled, sad: true },
          },
          async () => {
            const basicDetails = this.props.ConfigureProgramData.basicDetails;
            const oldClientStoryFileExists = basicDetails.oldClientStoryFileExists
            const {  keyBusinessChallenges,
              innovationEnablers,
              businessOutcomes } = this.props.ConfigureProgramData.clientStory;
            const ovStatus = basicDetails.overallStatus.toLowerCase();
            const currentUserRoleID = this.props.UserInformation.currentUserRoleID;
            const computeBOI = this.hadCompute;
            let shouldCompute = false;
            let clientApproverRole = this.state.clientApprover

            if (
              !computeBOI &&
              //ovStatus === 'approved' &&
              (currentUserRoleID !== 4 ||
                currentUserRoleID !== 7 ||
                currentUserRoleID !== 8 ||
                currentUserRoleID !== 16)
            ) {
              shouldCompute = true;
            }

            if(!keyBusinessChallenges.length || 
              !innovationEnablers.length ||
              !businessOutcomes.length){
              window.scrollTo({ top: 165, left: 165, behavior: 'smooth' });
              if(oldClientStoryFileExists){
                this.setState({
                  hasOldClientStoryFileError : true,
                  hasNoCSAttachment : false,
                  isDisabled: { ...this.state.isDisabled, sad: false }
                 }) 
              } else{
                this.setState({
                 hasNoCSAttachment : true,
                 hasOldClientStoryFileError : false,
                 isDisabled: { ...this.state.isDisabled, sad: false }
                })
              }
               return
            }

            let isValid = await this.validatePDEForm(shouldCompute);
            //check Client Approver
            let validClientApprover = this.checkClientApproverRole()
            if (!validClientApprover) {
              window.scrollTo({ top: 130, left: 185, behavior: 'smooth' });
              this.setState({
                isDisabled: { ...this.state.isDisabled, sad: false }
              })
              return
            }


            if (isValid.result && isValid.error === '') {
              const isSuccess = await this.props.getSFADetails("sad");
              if (isSuccess) {
                await this.props.handleSaveAsDraft(clientApproverRole);
              }
            }

            
            this.setState({
              isDisabled: { ...this.state.isDisabled, sad: false },
            });
          }
        );
      } catch (e) {
        console.log(e);
      }
    };

    onClickSubmitRejection = async () => {
      try {
        const clientApproverRole = this.state.clientApprover

        this.setState(
          {
            isDisabled: { ...this.state.isDisabled, send: true },
          },
          async () => {
            await this.props.handleSubmitRejection(clientApproverRole);
            this.setState({
              isDisabled: { ...this.state.isDisabled, send: false },
            });
          }
        );
      } catch (e) {
        console.log(e);
      }
    };

    onCloseProjectDataEntry = (origin, value) => {
      try {
        this.setState(
          {
            ...this.state,
            showProjectDataEntry: !this.state.showProjectDataEntry,
          },
          () => {
            this.props.removeClientStoryUpload();
            this.props.removeRecentClientStoryUpload(null);
            this.props.removeClientAlignmentUpload();
            this.props.removeRecentClientAlignmentUpload(null);
            this.props.isUploadCompleted(false);
          }
        );
      } catch (e) {
        console.log(e);
      }
    };

    closeProject = async (origin, value) => {
      try {
        const basicDetails = this.props.ConfigureProgramData.basicDetails;
        const selectedClient = this.props.SharedData.selectedClient;
        this.props.history.push('/DataIngestion');
        this.props.clearClientModalDetails();
        await LogRVDprojectAction({
          clientMasterID: selectedClient.value,
          clientProgramID: basicDetails.programId,
          clientProjectID: basicDetails.projectID,
          clientProjectStatus: ACC_PROJ,
          module: PDE,
        });
      } catch (e) {
        console.log(e);
      }
    };

    onExpandCollapse = (e, index) => {
      try {
        let elems = [];
        let caseString = e.currentTarget.id.includes('expandAll') ? 'expandAll' : 'collapseAll';
        switch (caseString) {
          case 'expandAll':
            elems = document.getElementsByClassName('collapse');
            for (var i = 1; i < elems.length; i++) {
              if (elems[i].className === 'collapse') {
                document
                  .getElementsByClassName('collapse')
                [i].parentElement.childNodes[1].children[0].click();
              }
            }

            this.setState({
              activeExpand: true,
              activeCollapse: false,
            });
            break;
          case 'collapseAll':
            elems = document.getElementsByClassName('collapse show');
            for (var i = 0; i < elems.length; i++) {
              if (elems[i].className === 'collapse show') {
                document
                  .getElementsByClassName('collapse show')
                [i].parentElement.childNodes[1].children[0].click();
              }
            }

            this.setState({
              activeExpand: false,
              activeCollapse: true,
            });
            break;
          default:
            break;
        }
      } catch (e) {
        console.log(e);
      }
      setTimeout(this.listenToScroll, 300);
    };

    handleSubmitForApproval = async () => {
      try {
        const basicDetails = this.props.ConfigureProgramData.basicDetails;
        const oldClientStoryFileExists = basicDetails.oldClientStoryFileExists;
        const {
          businessOutcomes,
          innovationEnablers,
          keyBusinessChallenges,
          isExistingProject
        } = this.props.ConfigureProgramData.clientStory;
        
        let isValid = await this.validatePDEForm(false);
        const isCSValid = businessOutcomes.length &&
        innovationEnablers.length &&
        keyBusinessChallenges.length
        if(isCSValid){
            this.setState({
              hasOldClientStoryFileError: false,
              hasNoCSAttachment: false,
            });
        } else{
          if(oldClientStoryFileExists){
            this.setState({
              hasOldClientStoryFileError: true,
              hasNoCSAttachment: false,
             }, () => {
              window.scrollTo({ top: 165, left: 165, behavior: 'smooth' });
              return;
            });
          } else{
            this.setState({
              hasOldClientStoryFileError: false,
              hasNoCSAttachment: true,
             }, () => {
              window.scrollTo({ top: 165, left: 165, behavior: 'smooth' });
              return;
            });
          }
        } 

        if (!isValid.result || !isCSValid) return;

        this.closeSubmitModal();
      } catch (err) {
        console.log(err);
      }
    };

    validatePDEForm = async (shouldCompute) => {
      const { projectDimensionsList, rvdSelectedTabs, basicDetails } =
        this.props.ConfigureProgramData;
      const ovStatus = basicDetails.overallStatus.toLowerCase();
      const currentUserRoleID = this.props.UserInformation.currentUserRoleID;
      const isSuperAdmin = this.props.UserInformation.isSuperAdmin;
      const financialIdx = projectDimensionsList.findIndex((dItem) => dItem.dimensionID === 1);

      let isValid =
        ovStatus === 'awaiting approval' &&
          (isSuperAdmin || basicDetails.assignedToRoleID === currentUserRoleID)
          ? {
            error: '',
            result: true,
          }
          : await this.props.validatePDEForm(shouldCompute);

      if (financialIdx > -1) {
        projectDimensionsList[financialIdx].subDimensionList.forEach((sdItem) => {
          const boiListUSD = sdItem.boiList.filter(
            (boiItem) => boiItem.boiUOM.toLowerCase() === 'usd'
          );
          const boiListNotUSD = sdItem.boiList.filter(
            (boiItem) => boiItem.boiUOM.toLowerCase() !== 'usd'
          );
          let hasEmptyUSD = boiListUSD.some((boiItem) => boiItem.RVDCommentHistory.length === 0);
          let hasEmptyNotUSD = boiListNotUSD.some(
            (boiItem) => boiItem.RVDCommentHistory.length === 0
          );

          if (
            ovStatus === 'awaiting approval' &&
            (isSuperAdmin || basicDetails.assignedToRoleID === currentUserRoleID)
          ) {
            hasEmptyUSD = boiListUSD.some((boiItem) => {
              return boiItem.boiStatus === 'Rejected' && boiItem.boiReason === '';
            });
            hasEmptyNotUSD = boiListNotUSD.some((boiItem) => {
              return boiItem.boiStatus === 'Rejected' && boiItem.boiReason === '';
            });
          }

          if (hasEmptyUSD) {
            rvdSelectedTabs[sdItem.subDimensionname.toLowerCase()] = 0;
          } else if (hasEmptyNotUSD) {
            rvdSelectedTabs[sdItem.subDimensionname.toLowerCase()] = 1;
          }
        });
      }

      this.props.setRVDSelectedTabs(rvdSelectedTabs);

      return isValid;
    };

    handleSendForFinalApproval = async () => {
      try {
        const basicDetails = this.props.ConfigureProgramData.basicDetails;
        const selectedClient = this.props.SharedData.selectedClient;
        const approvalForm = {
          stakeholder: this.state.stakeHolders,
          comment: this.state.comment,
        };
        this.setState(
          {
            isDisabled: { ...this.state.isDisabled, send: true },
          },
          async () => {
            await this.props.submitFinalApproval(approvalForm, (data) => {
              if (data) {
                this.closeSubmitModal();
                this.props.clearClientModalDetails();
                this.props.history.push('/DataIngestion');
              }
            });
            this.setState({
              isDisabled: { ...this.state.isDisabled, send: false },
            });
            await LogRVDprojectAction({
              clientMasterID: selectedClient.value,
              clientProgramID: basicDetails.programId,
              clientProjectID: basicDetails.projectID,
              clientProjectStatus: PROJ_DATA_APP_L3,
              module: PDE,
            });
          }
        );
      } catch (err) {
        console.log(err);
      }
    };

    handleComment = (e) => {
      try {
        this.setState({
          comment: e.value,
        });
      } catch (err) {
        console.log(err);
      }
    };

    setDefaultRadioValue = async () => {
      try {
        let projectDetails = this.props.ConfigureProgramData.projectDimensionsList;
        let isExisting784 = await this.props.isProfileExisting([7, 4]); //removed IO since super ad = io
        projectDetails.map((dimension, dIndex) => {
          dimension.subDimensionList.map((subDimension, SDIndex) => {
            subDimension.boiList.map((boi, bIndex) => {
              if (
                this.props.UserInformation.currentUserRoleID === 4 ||
                this.props.UserInformation.currentUserRoleID === 7 ||
                // this.props.UserInformation.currentUserRoleID === 8 ||
                (this.props.UserInformation.currentUserRoleID === 16 && isExisting784) ||
                (this.props.UserInformation.isSuperAdmin &&
                  this.props.ConfigureProgramData.mainSelectedProject.projectDetails.status !== '')
              ) {
                if (boi.boiStatus === '') {
                  this.props.setDefaultRadioValue('Approve', {
                    dimensionIndex: dIndex,
                    subDimensionIndex: SDIndex,
                    boiIndex: bIndex,
                  });
                }
              }
            });
          });
        });
      } catch (e) {
        console.log(e);
      }
    };

    showDisclaimerMessage = async () => {
      const selectedProject = this.props.ConfigureProgramData.projectDimensionsList;
      // let obj = [];
      // let bois = [];
      // let isRejected = false;
      let showNotifMessageArrtemp = {
        financial: false,
        experience: false,
        sustainability: false,
        talent: false,
        inclusiondiversity: false,
        custom: false,
      };
      selectedProject.map((dim) => {
        dim.subDimensionList.map((subdim) => {
          subdim.boiList.map((boi, i) => {
            if (
              boi.boiUOM.toLowerCase() === 'usd' &&
              dim.dimensionName.toLowerCase() !== 'financial'
            ) {
              showNotifMessageArrtemp[
                dim.dimensionName.replace(/\s/g, '').replace(/&/g, '').toLowerCase()
              ] = true;
            }
          });
        });
      });
      await this.setState({
        showNotifMessage: true,
        showNotifMessageArr: showNotifMessageArrtemp,
      });
      setTimeout(
        () =>
          this.setState({
            showNotifMessage: false,
            showNotifMessageArr: {
              financial: false,
              experience: false,
              sustainability: false,
              talent: false,
              inclusiondiversity: false,
              custom: false,
            },
          }),

        60000
      );
    };

    setIsReject = (e, userRoleID, indexes) => {
      try {
        let tempCurrentDimension = this.props.ConfigureProgramData.projectDimensionsList;
        let currentIndexes = this.state.currentBOIStatus;
        let data = this.state.radioButtonIdList;

        if (
          this.state.currentBOIStatus.status === '' ||
          (this.state.currentBOIStatus.dimensionIndex !== indexes.dimensionIndex &&
            this.state.currentBOIStatus.dimensionIndex !== 0) ||
          (this.state.currentBOIStatus.subDimensionIndex !== indexes.subDimensionIndex &&
            this.state.currentBOIStatus.subDimensionIndex !== 0) ||
          (this.state.currentBOIStatus.boiIndex !== indexes.boiIndex &&
            this.state.currentBOIStatus.boiIndex !== 0)
        ) {
          if (
            (this.state.currentBOIStatus.dimensionIndex !== indexes.dimensionIndex &&
              this.state.currentBOIStatus.dimensionIndex !== 0) ||
            (this.state.currentBOIStatus.subDimensionIndex !== indexes.subDimensionIndex &&
              this.state.currentBOIStatus.subDimensionIndex !== 0) ||
            (this.state.currentBOIStatus.boiIndex !== indexes.boiIndex &&
              this.state.currentBOIStatus.boiIndex !== 0)
          ) {
            this.props.saveRejectionReason(this.state.currentBOIStatus.status, '', indexes);
          }
          this.setState({
            currentBOIStatus: {
              status:
                tempCurrentDimension[indexes.dimensionIndex].subDimensionList[
                  indexes.subDimensionIndex
                ].boiList[indexes.boiIndex].boiStatus,
              dimensionIndex: indexes.dimensionIndex,
              subDimensionIndex: indexes.subDimensionIndex,
              boiIndex: indexes.boiIndex,
              ID: parseInt(e.id.split('-')[1]),
            },
          });
        } else {
          this.setState({
            currentBOIStatus: {
              status:
                tempCurrentDimension[indexes.dimensionIndex].subDimensionList[
                  indexes.subDimensionIndex
                ].boiList[indexes.boiIndex].boiStatus,
              dimensionIndex: indexes.dimensionIndex,
              subDimensionIndex: indexes.subDimensionIndex,
              boiIndex: indexes.boiIndex,
              ID: parseInt(e.id.split('-')[1]),
            },
          });
        }

        //true approve false reject
        data
          .filter((x) => x.id === parseInt(e.id.split('-')[1]))
          .map((y) => {
            return {
              ...(y.value = e.value),
            };
          });

        this.setState(
          {
            radioButtonIdList: data,
          },
          () => {
            this.setStakeHolders(this.state.isRejected, userRoleID);
          }
        );

        if (data.filter((x) => !x.value).length >= 1) {
          if (e.value) {
            this.setState(
              {
                isRejected: true,
              },
              () => {
                this.props.saveRejectionReason('Approve', '', indexes);
              }
            );
          } else {
            this.setState(
              {
                rejectionModal: {
                  show: false,
                  dimensionIndex: indexes.dimensionIndex,
                  subDimensionIndex: indexes.subDimensionIndex,
                  boiIndex: indexes.boiIndex,
                },
                isRejected: true,
              },
              () => {
                this.props.saveRejectionReason('Rejected', '', indexes, true);
              }
            );
          }
        } else {
          this.setState(
            {
              rejectionModal: {
                show: false,
                dimensionIndex: 0,
                subDimensionIndex: 0,
                boiIndex: 0,
              },
              isRejected: false,
            },
            () => {
              this.props.saveRejectionReason('Approve', '', indexes);
            }
          );

          // document.body.style.overflowY = 'visible'
        }
      } catch (err) {
        console.log(err);
      }
    };

    saveReject = (indexes) => {
      try {
        this.props.saveRejectionReason('Rejected', this.state.tempReason, {
          dimensionIndex: indexes.dimensionIndex,
          subDimensionIndex: indexes.subDimensionIndex,
          boiIndex: indexes.boiIndex,
        });
        this.setState({
          rejectionModal: {
            show: false,
          },
          rejectReasonClicked: true,
          tempReason: '',
          submitReject: false,
          currentBOIStatus: {
            status: '',
            dimensionIndex: 0,
            subDimensionIndex: 0,
            boiIndex: 0,
            ID: 0,
          },
        });

        document.body.style.overflowY = 'visible';

        let tState = {
          isSelectProjectDisabled: true,
          isCommentDisabled: false,
          isSaveAsDraftDisabled: false,
        };
        this.props.setConfProgButtonState(tState);
      } catch (e) {
        console.log(e);
      }
    };

    updateReason = (e, indexes) => {
      try {
        this.props.saveRejectionReason('Rejected', e.value, {
          dimensionIndex: indexes.dimensionIndex,
          subDimensionIndex: indexes.subDimensionIndex,
          boiIndex: indexes.boiIndex,
        });
      } catch (e) {
        console.log(e);
      }
    };

    onSelectAccordion = (e) => {
      try {
        let accordionCount = this.state.accordionCount;
        let activeCollapse = false; //accordion close
        let activeExpand = false; //accordion open

        if (e === null) {
          accordionCount -= 1;
        } else {
          accordionCount += 1;
        }

        if (this.props.ConfigureProgramData.basicDetails.noOfSubdimensions === accordionCount) {
          activeExpand = true;
          activeCollapse = false;
        } else if (accordionCount === 0) {
          activeCollapse = true;
          activeExpand = false;
        }

        this.setState({
          accordionCount: accordionCount,
          activeExpand: activeExpand,
          activeCollapse: activeCollapse,
        });
      } catch (e) {
        console.log(e);
      }
      setTimeout(this.listenToScroll, 300);
    };

    onClickCustomBOI = async (boiId, isOpen) => {
      const programId = this.props.ConfigureProgramData.mainSelectedProject.programID;
      this.setState({
        showCustomBOI: !this.state.showCustomBOI,
      });
      try {
        if (isOpen) await this.props.getCustomBOIDetails(programId, boiId);
        const data = this.props.SharedData.customBOI;
        const hasData = Object.keys(data).length > 0 ? true : false;
        setTimeout(() => {
          this.setState({
            dimensionName: hasData ? data[0].CoreDimensionName : '',
            subDimensionName: hasData ? data[0].SubDimensionName : '',
            boi: {
              ClientName: hasData ? data[0].ClientName : '',
              offeringName: hasData ? data[0].Offering : '',
              boiName: hasData ? data[0].BOIName : '',
              uom: hasData ? data[0].UOM : '',
              description: hasData ? data[0].BOIDescription : '',
              LeadingIndicator: hasData ? data[0].LeadingIndicator : '',
              comment: hasData ? data[0].BOIAdditionComment : '',
              MetricCalculation: hasData ? data[0].MetricCalculation : '',
              customParameterList: hasData
                ? data[0].CustomParameterList.map((x, i) => {
                  return {
                    ParameterCustomCode: x.ParameterName,
                    ParameterUOM: x.ParameterUOM,
                  };
                })
                : [],
              AggregationLogic: hasData ? data[0].AggregationLogic : '',
              SubOffering: hasData ? data[0].SubOfferingName : '',
              AggregationLogicId: hasData ? data[0].BOIDefinitionMasterID : 0,
              MetricReason: hasData ? data[0].ReasonForNoBOIDefinition : '',
              AggregateReason: hasData ? data[0].ReasonForNoAggregationLogic : '',
            },
          });
        }, 10);
      } catch (err) {
        console.log(err);
      }
    };

    handleFetchUpdatedBOI = async (e) => {
      let selectProject = this.props.ConfigureProgramData.mainSelectedProject;

      let programDetails = {
        programID: selectProject.programID,
        programName: selectProject.programName,
        creationYear: selectProject.creationYear,
      };

      let projectDetails = {
        ID: selectProject.projectDetails.ID,
        assignedTo: selectProject.projectDetails.assignedTo,
        clientProjectID: selectProject.projectDetails.clientProjectID,
        clientProjectName: selectProject.projectDetails.clientProjectName,
        cssClass: selectProject.projectDetails.cssClass,
        deliveryCenterID: selectProject.projectDetails.deliveryCenterID,
        deliveryCenterName: selectProject.projectDetails.deliveryCenterName,
        status: selectProject.projectDetails.status,
        projectID: selectProject.projectDetails.projectID,
        status: selectProject.projectDetails.status,
        assignedToRoleId: selectProject.projectDetails.assignedToRoleId,
        createdBy: selectProject.projectDetails.createdBy,
        creationDate: selectProject.projectDetails.creationDate,
        deliveryLocations: selectProject.projectDetails.deliveryLocations,
        reportedBy: selectProject.projectDetails.reportedBy,
        reportedMonth: selectProject.projectDetails.reportedMonth,
        statusID: selectProject.projectDetails.statusID,
        lastReportingDate: selectProject.projectDetails.lastReportingDate,
        pdeModifiedDate: selectProject.projectDetails.pdeModifiedDate, //
      };
      await this.props.getUpdatedBOI(programDetails, projectDetails);
      this.showDisclaimerMessage();
    };

    handleShowResetAllModal = () => {
      this.setState({
        showResetAllModal: !this.state.showResetAllModal,
      });
    };

    handleConfirmResetAll = async () => {
      await this.props.handleResetAll();
      this.setState({
        showResetAllModal: !this.state.showResetAllModal,
      });
    };

    handleBOIComment = (isShow, boiItem) => {
      this.setState({
        showCommentary: isShow,
        commentBOIDetails: boiItem,
      });
    };

    handleBOICommentHistory = (isShow, boiItem, boiList) => {
      this.setState({
        showCommentaryHistory: isShow,
        commentBOIDetails: boiItem,
        boiList: boiList,
      });
    };

    onCloseSubmitDataRecord = () => {
      this.setState(
        {
          showSubmitDataRecord: !this.state.showSubmitDataRecord,
          showRepPerHighlighted: true,
        },
        () => {
          const element = document.getElementById('configCardID').childNodes;
          if (element[0].classList.contains('show')) return;

          setTimeout(() => {
            const btn = document.getElementById('basicDetailsExpand');
            if (btn) document.getElementById('basicDetailsExpand').click();
          }, 300);
        }
      );
    };

    handleConfirmSubmitRecord = () => {
      this.closeRequestModal();
      this.setState({
        showSubmitDataRecord: !this.state.showSubmitDataRecord,
        showConfirmApprovalModal: false,
      });
    };

    setCumulativeModal = () => {
      this.props.setCumulativeModal(false);
    };

    handleComputeAll = (isFromCompute) => {
      console.log("compute",)
      this.hadCompute = true;


      this.props.handleComputeAll(isFromCompute, this.onExpandCollapse);
    };

    showClientStoryOnClick = (clientModalType) => {
      try {
        if (!this.props.ConfigureProgramData.isUploadCompleted) {
          this.props.removeClientStoryUpload();
        }
        this.setState(
          {
            showClientStory: !this.state.showClientStory,
            clientModalType: clientModalType,
          },
          async () => {
            if (clientModalType === 'story') {
              await this.props.getClientStoryFileHistory();
            } else {
              //acknowledgement
              await this.props.getClientAcknowledgementFileHistory();
            }
          }
        );
      } catch (e) {
        console.log(e);
      }
    };

    onCloseCSAttachmentError = () => {
      try {
        this.setState({
          hasNoCSAttachment: false,
          hasOldClientStoryFileError: false
        });
      } catch (e) {
        console.log(e);
      }
    };

    onCloseCAAttachmentError = () => {
      try {
        this.setState({
          hasNoCAAttachment: false,
        });
      } catch (e) {
        console.log(e);
      }
    };

    formatValue = (itemId, value, uom) => {
      if ([null, undefined, ''].indexOf(value) > -1) return '--';

      const wholeNum = value.toString().split('.')[0];
      let valueTemp = `${value} ${uom}`;

      if (wholeNum.length > 4) {
        if (wholeNum.length > 9) {
          valueTemp = `${Math.round(parseFloat(value) / 1000000000)} B ${uom}`;
        } else if (wholeNum.length > 6) {
          valueTemp = `${Math.round(parseFloat(value) / 1000000)} M ${uom}`;
        } else {
          valueTemp = `${Math.round(parseFloat(value) / 1000)} K ${uom}`;
        }

        return (
          <>
            <a className="value-tooltip" data-tip data-for={`${itemId}_format`}>
              {valueTemp}
            </a>
            <ReactTooltip id={`${itemId}_format`} type="dark" effect="solid">
              <span>{`${value} ${uom}`}</span>
            </ReactTooltip>
          </>
        );
      }

      return valueTemp;
    };
    setClientApproverRoleList = async () => {
      try {
        let subMenuId = GetSubMenuId("Project Data Entry")
        const data = await HttpGet('ReportValueDelivered/GetClientApproverRoleList?SubMenuID=' + subMenuId);
        let list = [];

        if (Object.keys(data).length > 0) {
          if (data.ClientApproverRoles.length > 0) {
            data.ClientApproverRoles.map((i) => {
              list.push({
                label: i.ClientApproverRoleName,
                text: i.ClientApproverRoleName,
                value: i.ID,
              });
            });
          }

          this.setState({
            clientApproverRoleList: list,
          });
        }
      } catch (e) {
        console.log(e);
      }
    };

    handleShowFileHistory = async (e) => {
      if (e) await this.props.getClientFileHistory();
      this.setState({ showFileHistory: e });
    };

    handleSelectClientApproverRole = (e) => {
      try {
        if (!this.state.editedAnyFieldOnce && this.props.ConfigureProgramData.basicDetails.overallStatus === 'Approved') {
          this.checkForInvalidEmailsOnLanding();
          this.setEditedAnyFieldOnce(true);
        }
        this.setState({
          isDisabled: { ...this.state.isDisabled, sfa: false },
          clientApprover:
          {
            ...this.state.clientApprover,
            clientApproverRole: e,
            clientApproverRoleEmpty: (e.value === ""),
            otherSpecifyEmpty: (e.value === 4) ? false : true, //if others
            otherSpecify: ""
          }
        });
      } catch (e) {
        console.log(e)
      }
    }

    handleOtherClientApproverRole = (e) => {
      if (!IsValidText(e.value)) {
        this.setState({
          invalidOtherClientApprovalRole: true
        })
      } else {
        this.setState({
          invalidOtherClientApprovalRole: false
        })
      }
      this.setState({
        isDisabled: { ...this.state.isDisabled, sfa: false },
        clientApprover: {
          ...this.state.clientApprover,
          otherSpecify: e.value,
          otherSpecifyEmpty: (e.value === "")
        }
      });
    }

    handleSetClientApproverRole = () => {
      const projectDetails = this.props.ConfigureProgramData.mainSelectedProject.projectDetails
      const clientApproverList = this.state.clientApproverRoleList
      let clientApproverRole = [];


      if (IsValidStr(projectDetails.clientApproverRole)) {
        clientApproverList.map(x => {
          if (x.label.toLowerCase() === projectDetails.clientApproverRole.toLowerCase()) {
            clientApproverRole.push(x)
          } else {
            if (x.label.toLowerCase() === 'others')
              clientApproverRole.push(x)
          }
        })

        if (clientApproverRole.length > 0 && clientApproverRole[0].label.toLowerCase() !== 'others') {
          this.setState({
            clientApprover: {
              ...this.state.clientApprover,
              clientApproverRole: clientApproverRole[0],
              clientApproverRoleEmpty: false
            }
          })
        } else { //other is selected
          this.setState({
            clientApprover: {
              clientApproverRole: clientApproverRole[0],
              clientApproverRoleEmpty: false,
              otherSpecify: projectDetails.clientApproverRole,
              otherSpecifyEmpty: false
            }
          })
        }
      }
    }

    checkClientApproverRole = () => {
      const clientApproverRole = this.state.clientApprover.clientApproverRole;
      const other = this.state.clientApprover.otherSpecify
      let isValid = false

      if (clientApproverRole === "") {
        this.setState({
          clientApprover: {
            ...this.state.clientApprover,
            clientApproverRoleEmpty: true
          }
        });
      } else if (other === "" && clientApproverRole.label === "Others") {
        this.setState({
          clientApprover: {
            ...this.state.clientApprover,
            otherSpecifyEmpty: true
          }
        });
      } else {
        this.setState({
          clientApprover: {
            ...this.state.clientApprover,
            clientApproverRoleEmpty: false,
            otherSpecifyEmpty: false
          }
        });
      }
      if (this.state.clientApprover.clientApproverRoleEmpty || this.state.clientApprover.otherSpecifyEmpty) {
        isValid = false
      } else {
        isValid = true
      }

      return isValid
    }
    onSendReminder = async () => {
      try {
        const { emailAddress } = this.props.UserInformation;
        let data = {
          view: "Project Data Entry",
          message: "Reminder notification is sent for the approver successfully!",
          user: emailAddress
        }

        this.setState({
          isDisabledReminderButton: true
        }, async () => await this.props.handleSendReminderPDE(data))
      } catch (error) {
        console.log(error);
      }
    }

    setRedirectModal = () => {
      this.setState({
        showRedirectModal: !this.state.showRedirectModal
      })
    }

    redirectToPPC = () => {
      this.props.getBusinessAdvisorListByClientID();
      let details = this.props.ConfigureProgramData.basicDetails;
      this.props.history.push(`/ProgramProjectConfig/${this.props.SharedData.selectedClient.text}/${details.programId}/${details.projectID}`)
    }

    validateMappedFields = () => {
      try {
        const {
          basicDetails,
          businessAdvisorList,
        } = this.props.ConfigureProgramData;

        if (!IsObjEmpty(basicDetails)) {
          let selectedProjCreator = basicDetails.projectCreator;
          let selecteddataSubmitter = basicDetails.dataSubmitter;
          let selectedioapprover = basicDetails.ioApprover;
          // let selectedBA = businessAdvisorList;
          let selectedCXLead = basicDetails.cxLead;
          let selectedOALead = basicDetails.oaLead;

          let tempIsValidFields = {
            projCreator: true,
            dataSubmitter: true,
            ioApprover: true,
            cxl: true,
            oal: true,
            ba: true,
          }
          let invalidProj = [];
          let mappedFieldErrMsgs = [];
          // If no Program Creator is mapped to the program and there are options available
          if (!IsValid(selectedProjCreator)) {
            tempIsValidFields.projCreator = false;
            invalidProj.push("Project Creator");
          }
          /* if (!IsValid(selecteddataSubmitter)) {
            tempIsValidFields.dataSubmitter = false;
            invalidProj.push("Data Submitter");   
          } */
          if (!IsValid(selectedioapprover)) {
            tempIsValidFields.ioApprover = false;
            invalidProj.push("IO Approver");
          }
          /* Check if there are no mapped CX lead/OAL and if not single option 
          since if there is only one option it will be auto selected as per current implementation */
          if (!IsValid(selectedCXLead)) {
            tempIsValidFields.cxl = false;
            invalidProj.push("CX Lead");
          }
          if (!IsValid(selectedOALead)) {
            tempIsValidFields.oal = false;
            invalidProj.push("Operations Account Lead");
          }
          // if (selectedBA.length === 0) {
          //   tempIsValidFields.oal = false;
          //   invalidApprovers.push("Business Advisor");
          // }

          // Format Error Messages
          if (invalidProj.length > 0) {
            let tmpSRoles = formatMultiLabel(invalidProj);
            let creatorInvalidMsg = 'No ' + tmpSRoles + ' is mapped for the project. Please navigate to Program & Project configuration to update '
              + tmpSRoles + ' for the project.';
            mappedFieldErrMsgs.push(creatorInvalidMsg);
          }

          this.setState({
            isValidMappedFields: {
              projCreator: tempIsValidFields.projCreator,
              dataSubmitter: tempIsValidFields.dataSubmitter,
              ioApprover: tempIsValidFields.ioApprover,
              cxl: tempIsValidFields.cxl,
              oal: tempIsValidFields.oal,
              ba: tempIsValidFields.ba,
              mappedFieldErrMsgs: mappedFieldErrMsgs
            }
          })
        }
      } catch (e) {

      }

    }

    getDisclaimerDetails = async () => {
      try {
        const {
          basicDetails,
        } = this.props.ConfigureProgramData;

        let enterpriseID = this.props.UserInformation.enterpriseID

        if (!IsObjEmpty(basicDetails)) {
          let subOfferingID = 0;
          if (!IsArrayEmpty(basicDetails.subOfferingList)) {
            subOfferingID = basicDetails.subOfferingList[0].SubOfferingID;
          }
          let body = {
            ClientProgramID: parseInt(basicDetails.programId),
            ClientProjectID: basicDetails.projectID,
            IsPDE: true,
            Username: enterpriseID,
            OfferingID: basicDetails.offeringID,//selectedProjectDetails.OfferingID,
            SubOfferingID: subOfferingID,
          };
          let subMenuId = GetSubMenuId("Project Data Entry");
          let data = await HttpPost(`User/GetDisclaimerDetails?SubMenuID=${subMenuId}`, body);
          if (data.status === undefined && !IsObjEmpty(data)) {
            if (data.DisclaimerFlag) {
              let isValidMappedFields = this.state.isValidMappedFields;
              let mappedFieldErrMsgs = isValidMappedFields.mappedFieldErrMsgs;
              let invalidApprovers = IsValid(data.Roles) ? data.Roles : [];
              // Remove updated disclaimer for PPC
              invalidApprovers = invalidApprovers.filter(role => { return role !== 'Program and Project Configurator' })
              if (invalidApprovers.length > 0) {
                let tmpRoles = formatMultiLabel(invalidApprovers);
                let approverInvalidMsg = 'Please note that ' + tmpRoles + ' is updated for the project data entry.';
                mappedFieldErrMsgs.push(approverInvalidMsg);
              }

              this.setState({
                isValidMappedFields: {
                  ...isValidMappedFields,
                  mappedFieldErrMsgs: mappedFieldErrMsgs
                }
              })
            }
          }
        }
      } catch (e) {

      }
    }

    render() {
      const {
        showSelectProject,
        basicDetails,
        projectDimensionsList,
        buttonState,
        isProjectSelected,
        isReportProjectSelected,
        receiverDetails,
        mainSelectedProject,
        rejectedComments,
        isWriteAccess,
        configProgUIElems,
        fileData,
        isRefreshed,
        isExistingDataEntry,
        showCumulativeModal,
        clientStoryFile,
        removeClientStoryFileId,
        businessAdvisorList,
        clientAcknowledgementFileHistory
      } = this.props.ConfigureProgramData;

      const {
        userProfiles,
        currentUserRoleID,
        emailAddress,
        isSuperAdmin,
        enterpriseID,
        isIOAdmin,
      } = this.props.UserInformation;

      const { selectedClient } = this.props.SharedData;

      const { RVDComponents } = this.props.AccessValidationData;

      const {
        onSaveSelectProject,
        handleExpandCollapseYearQtr,
        handleParameterChange,
        getSFADetails,
        handleSFACommentChange,
        onClickSelectProjectSFA,
        handleSubmitRejection,
      } = this.props;

      const adminRole = TryGetObjValueAlt(
        mainSelectedProject,
        -1,
        'projectDetails.assignedToRoleId'
      );

      let clientID = selectedClient.value;
      let isExistingPPC = userProfiles.some(
        (x) => [12].some((prof) => prof === x.ProfileID) && x.ClientMasterId === clientID
      );
      let isExistingBA = userProfiles.some(
        (x) => [15].some((prof) => prof === x.ProfileID) && x.ClientMasterId === clientID
      );
      let isExistingIO = userProfiles.some(
        (x) => [8].some((prof) => prof === x.ProfileID) && x.ClientMasterId === clientID
      );
      let isExistingIOA = userProfiles.some((x) => [8].some((prof) => prof === x.ProfileID) && x.ClientMasterId === clientID);
      let isExistingCXL = userProfiles.some((x) => [7].some((prof) => prof === x.ProfileID) && x.ClientMasterId === clientID);
      // let isExistingIO = this.props.isProfileExisting([8]);
      let isExistingPPCBA = isExistingPPC || isExistingBA;
      return (
        <ConfigureProgramPresentational
          rejectionModal={this.state.rejectionModal}
          reportingYearOptions={this.state.reportingYearOptions}
          approvalRequestModal={this.state.approvalRequestModal}
          closeRequestModal={this.closeRequestModal}
          clientList={this.state.clientList}
          showRejectionModal={this.state.showRejectionModal}
          closeRejectionModal={this.closeRejectionModal}
          showcomments={this.state.showcomments}
          closeCommentsSection={this.closeCommentsSection}
          showSubmitRejectionModal={this.state.showSubmitRejectionModal}
          closeSubmitRejectionModal={this.closeSubmitRejectionModal}
          showSubmitModal={this.state.showSubmitModal}
          closeSubmitModal={this.closeSubmitModal}
          handleSendForApproval={this.handleSendForApproval}
          onUpdateProject={this.onUpdateProject}
          onExpandCollapse={this.onExpandCollapse}
          closeProject={this.closeProject}
          onCloseProjectDataEntry={this.onCloseProjectDataEntry}
          onClickSaveAsDraft={this.onClickSaveAsDraft}
          showProjectDataEntry={this.state.showProjectDataEntry}
          showRedirectModal={this.state.showRedirectModal}
          handleSubmitForApproval={this.handleSubmitForApproval}
          setIsReject={this.setIsReject}
          isRejected={this.state.isRejected}
          fileIsValid={this.state.fileIsValid}
          saveReject={this.saveReject}
          updateReason={this.updateReason}
          onClickSubmitRejection={this.onClickSubmitRejection}
          activeExpand={this.state.activeExpand}
          activeCollapse={this.state.activeCollapse}
          rejectReasonClicked={this.state.rejectReasonClicked}
          submitReject={this.state.submitReject}
          tempReason={this.state.tempReason}
          onSelectAccordion={this.onSelectAccordion}
          handleFetchUpdatedBOI={this.handleFetchUpdatedBOI}
          hadCompute={this.hadCompute}
          errorUpload={this.state.errorUpload}
          //actions
          onSaveSelectProject={onSaveSelectProject}
          showSelectProject={showSelectProject}
          handleSFACommentChange={handleSFACommentChange}
          handleParameterChange={handleParameterChange}
          getSFADetails={getSFADetails}
          onClickSelectProjectSFA={onClickSelectProjectSFA}
          handleComputeAll={this.handleComputeAll}
          handleSubmitRejection={handleSubmitRejection}
          getSRDetails={getSRDetails}
          getUpdatedBOI={getUpdatedBOI}
          resetRefresh={resetRefresh}
          //reducers
          basicDetails={basicDetails}
          projectDimensionsList={projectDimensionsList}
          buttonState={buttonState}
          isProjectSelected={isProjectSelected}
          isReportProjectSelected={isReportProjectSelected}
          receiverDetails={receiverDetails}
          userProfiles={userProfiles}
          selectedClient={selectedClient}
          RVDComponents={RVDComponents}
          mainSelectedProject={mainSelectedProject}
          rejectedComments={rejectedComments}
          loggedInUserRoleID={currentUserRoleID}
          currentUserRoleID={isSuperAdmin ? adminRole : currentUserRoleID}
          isWriteAccess={isWriteAccess}
          stakeHolders={this.state.stakeHolders}
          submitStakeHolders={this.state.submitStakeHolders}
          handleComment={this.handleComment}
          emailAddress={emailAddress}
          isSuperAdmin={isSuperAdmin}
          // isIOAdmin={isIOAdmin}
          configProgUIElems={configProgUIElems}
          fileData={fileData}
          isRefreshed={isRefreshed}
          enterpriseID={enterpriseID}
          isExistingDataEntry={isExistingDataEntry}
          // send for approval modal
          handleSendForFinalApproval={this.handleSendForFinalApproval}
          onDropAccepted={this.onDropAccepted}
          onDropRejected={this.onDropRejected}
          onDeleteFile={this.onDeleteFile}
          clearUpload={this.clearUpload}
          //Custom BOI
          onClickCustomBOI={this.onClickCustomBOI}
          showCustomBOI={this.state.showCustomBOI}
          subDimensionName={this.state.subDimensionName}
          dimensionName={this.state.dimensionName}
          boi={this.state.boi}
          //Confirm BOI Modal
          showConfirmApprovalModal={this.state.showConfirmApprovalModal}
          onClickConfirmBoi={this.onClickConfirmBoi}
          //BOI Comment
          handleBOIComment={this.handleBOIComment}
          handleBOICommentHistory={this.handleBOICommentHistory}
          showCommentary={this.state.showCommentary}
          showCommentaryHistory={this.state.showCommentaryHistory}
          commentBOIDetails={this.state.commentBOIDetails}
          boiList={this.state.boiList}
          isComment={this.state.isComment}
          //Reset All
          showResetAllModal={this.state.showResetAllModal}
          handleShowResetAllModal={this.handleShowResetAllModal}
          handleConfirmResetAll={this.handleConfirmResetAll}
          //Submit Data Record
          showSubmitDataRecord={this.state.showSubmitDataRecord}
          showRepPerHighlighted={this.state.showRepPerHighlighted}
          onCloseSubmitDataRecord={this.onCloseSubmitDataRecord}
          handleConfirmSubmitRecord={this.handleConfirmSubmitRecord}
          //leading indicators
          showCumulativeModal={showCumulativeModal}
          setCumulativeModal={this.setCumulativeModal}
          //client modal
          showClientStoryOnClick={this.showClientStoryOnClick}
          showClientStory={this.state.showClientStory}
          clientModalType={this.state.clientModalType}
          //client story and client alignment panel
          clientStoryFile={clientStoryFile}
          hasNoCSAttachment={this.state.hasNoCSAttachment}
          hasOldClientStoryFileError={this.state.hasOldClientStoryFileError}
          onCloseCSAttachmentError={this.onCloseCSAttachmentError}
          hasNoCAAttachment={this.state.hasNoCAAttachment}
          onCloseCAAttachmentError={this.onCloseCAAttachmentError}
          removeClientStoryFileId={removeClientStoryFileId}
          showNotifMessageArr={this.state.showNotifMessageArr}
          showNotifMessage={this.state.showNotifMessage}
          //addeditbusinessadvisor
          // showAddEditBA={this.state.showAddEditBA}
          // handleAddEditBA={this.handleAddEditBA}
          businessAdvisorList={businessAdvisorList}
          businessAdvisor={basicDetails.businessAdvisor}
          // isErrorBA={this.state.isErrorBA}
          // isEntryApproved={this.state.isEntryApproved}
          //CPPoints for BA Modal
          showCPPointsModal={this.state.showCPPointsModal}
          onClickYesNoBA={this.onClickYesNoBA}
          handleCPYesNo={this.handleCPYesNo}
          onCXSendForApproval={this.onCXSendForApproval}
          formatValue={this.formatValue}
          clientApproverRoleList={this.state.clientApproverRoleList}
          //
          isExistingPPC={isExistingPPC}
          isExistingBA={isExistingBA}
          isExistingIO={isExistingIO}
          isExistingPPCBA={isExistingPPCBA}
          isExistingIOA={isExistingIOA}
          isExistingCXL={isExistingCXL}
          history={this.props.history}
          isDisabled={this.state.isDisabled}
          //
          checkCumulative={this.checkCumulative}
          //File History
          showFileHistory={this.state.showFileHistory}
          handleShowFileHistory={this.handleShowFileHistory}
          clientAcknowledgementFileHistory={clientAcknowledgementFileHistory}
          //flexibility
          handleUpdateReceiverDetails={this.props.handleUpdateReceiverDetails}
          //clientApprover
          clientApprover={this.state.clientApprover}
          handleSelectClientApproverRole={this.handleSelectClientApproverRole}
          handleOtherClientApproverRole={this.handleOtherClientApproverRole}
          invalidOtherClientApprovalRole={this.state.invalidOtherClientApprovalRole}
          checkClientApproverRole={this.checkClientApproverRole}
          handleSetClientApproverRole={this.handleSetClientApproverRole}
          onSendReminder={this.onSendReminder}
          isDisabledReminderButton={this.state.isDisabledReminderButton}
          positionFixed={this.state.positionFixed}
          redirectToPPC={this.redirectToPPC}
          setRedirectModal={this.setRedirectModal}
          isValidMappedFields={this.state.isValidMappedFields}
          inValidEditorFields={this.state.inValidEditorFields}
          changeInvalidEditorFields={this.changeInvalidEditorFields}
          anyInvalidEmails={this.state.anyInvalidEmails}
          editedAnyFieldOnce={this.state.editedAnyFieldOnce}
          setEditedAnyFieldOnce={this.setEditedAnyFieldOnce}
          checkForInvalidEmailsOnLanding={this.checkForInvalidEmailsOnLanding}
          isComputeDisabled={this.state.isComputeDisabled}
          setIsComputeDisabled={this.setIsComputeDisabled}

        />
      );
    }
  }

  return ConfigureProgramContainer;
})();

export default connect(
  (state) => {
    return {
      ConfigureProgramData: state.ConfigureProgramData,
      clientID: state.SharedData.selectedClient.value,
      reportingYear: state.ClientConfigurationData.clientDetails.reportingYear,
      SharedData: state.SharedData,
      UserInformation: state.userInformation,
      AccessValidationData: state.AccessValidationData,
    };
  },
  (dispatch) => 
    bindActionCreators(
      Object.assign(
        {},
        {
          onSaveSelectProject,
          handleParameterChange,
          getSFADetails,
          handleSFACommentChange,
          handleSaveAsDraft,
          onClickSelectProjectSFA,
          handleComputeAll,
          handleResetAll,
          clearClientModalDetails,
          setConfProgButtonState,
          validatePDEForm,
          submitFinalApproval,
          saveRejectionReason,
          handleSubmitRejection,
          setDefaultRadioValue,
          getSRDetails,
          getCustomBOIDetails,
          getUpdatedBOI,
          resetRefresh,
          setCumulativeModal,
          validateRejectionComment,
          getClientStoryFileHistory,
          getClientAcknowledgementFileHistory,
          removeClientStoryUpload,
          removeClientAlignmentUpload,
          removeRecentClientStoryUpload,
          removeRecentClientAlignmentUpload,
          isUploadCompleted,
          onSaveBusinessAdvisor,
          onCPPointsYesNo,
          setRVDSelectedTabs,
          isProfileExisting,
          getClientFileHistory,
          handleUpdateReceiverDetails,
          handleSendReminderPDE,
          getReminderCount,
          setEmailError,
          getBusinessAdvisorListByClientID
        }
      ),
      dispatch
    )
)(withRouter(ConfigureProgram));
